@import "assets/scss/master";
@import "assets/scss/_include-media-lib/include-media";

.form-area {
    min-height: 100vh;
    @include media(">medium-screen") {
        // #{$relaxed__mode__template__selector} {
        min-height: 82vh;
        // }
    }

    @include media("<=small-screen") {
        height: 100%;
        position: absolute;
        align-items: center;
        justify-content: center;
        display: flex;
        width: 100% !important;
    }
}

.signin--container {
    min-height: 100vh;
    @include media("<=small-screen") {
        height: 100%;
        position: absolute;
        align-items: center;
        justify-content: center;
        display: flex;
        width: 100% !important;
    }
    #{$white__mode__theme__selector} {
        background-color: $light__theme_color;
    }
    #{$dark__mode__theme__selector} {
        background: $dark__mode__theme__background__color;
    }
    @include media(">medium-screen") {
        // #{$relaxed__mode__template__selector} {
        padding: 5px 100px;
        // }
    }

    @include media("<=medium-screen") {
        #{$white__mode__theme__selector} {
            background: $light__theme_color;
        }
    }

    &__footer {
        position: relative !important;
        bottom: -36px;
        border-radius: 0px 0px 20px 20px !important;
        padding: 20px 24px;
        border-top: 1px solid $white__mode__border__color;
        #{$dark__mode__theme__selector} {
            border: none;
            background-color: $dark__mode__theme__grey__color !important;
        }
        @include media("<=medium-screen") {
            display: none;
        }

        #{$relaxed__mode__template__selector} {
            display: none;
        }
    }

    .row {
        // #{$relaxed__mode__template__selector} {
        @include media(">medium-screen") {
            margin-left: 0rem !important;
            margin-right: 0rem !important;
        }
        // }
        border: 1px solid $white__mode__border__color;
        border-top: none;
        #{$relaxed__mode__template__selector} {
            border: 1px solid $white__mode__border__color;
        }
        #{$dark__mode__theme__selector} {
            border: none;
        }
    }
}

.signin-con {
    @include media(">medium-screen") {
        #{$white__mode__theme__selector} {
            background: $light__theme_color;
        }
        #{$dark__mode__theme__selector} {
            background: $dark__mode__theme__grey__color;
        }

        border-radius: 0px 0px 20px 20px;
        #{$relaxed__mode__template__selector} {
            border-radius: 20px;
        }
        &.initial__container {
            background: none;
        }
    }
}

.signin {
    &__close-icon {
        position: fixed;

        top: 150px;
        width: fit-content;
        cursor: pointer;

        text-align: right;

        right: 150px;

        @include media("<=medium-screen") {
            right: 20px;
        }
    }

    .signin-header {
        font-weight: 600;
        font-size: 2rem;
        line-height: 142%;
        color: $black__theme_color;

        // #{$relaxed__mode__template__selector} {
        font-weight: 600;
        font-size: 32px;
        line-height: 40px;
        @include media(">medium-screen") {
            text-align: center;
            margin-bottom: -5px;
        }
        // }

        // span {
        //     width: 10px !important;
        //     white-space: nowrap !important;
        //     overflow: hidden !important;
        //     text-overflow: ellipsis !important;
        // }

        #{$dark__mode__theme__selector} {
            color: $light__theme_color;
            // font-size: 26px !important;
        }
    }

    .signin-sub__text {
        font-size: 1.125rem;
        line-height: 1.625rem;
        color: $muted__theme_color_2;

        // #{$relaxed__mode__template__selector} {
        font-size: 1rem;
        line-height: 24px;
        @include media(">medium-screen") {
            text-align: center;
            font-size: 20px;
            line-height: 28px;
            font-weight: 400;
            margin-top: 24px;
            margin-bottom: 40px;
        }
        // }

        #{$dark__mode__theme__selector} {
            color: $dark__mode__text__theme__color;
        }
    }
}

.otp__group {
    width: 385px !important;
    margin-left: auto;
    margin-right: auto;
    @include media(">medium-screen") {
        margin-top: 100px;
    }

    #{$relaxed__mode__template__selector} {
        margin-top: 0px;
    }
    &.initial__content {
        width: 624px !important;
        margin-left: 0;
    }

    @include media("<=medium-screen") {
        padding: 0 1rem;
        width: unset !important;
        // still needed for initial sign in page
        // height: 700px;
        // overflow-y: auto;
        // margin: 100px auto;

        &.initial__content {
            width: fit-content !important;
            margin-left: auto;
            margin-right: auto;
        }
    }
}
