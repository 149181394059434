@import "assets/scss/master";
@import "assets/scss/_include-media-lib/include-media";

.mobile-scroll-navigator {
    background-color: $white__mode__background;
    height: 2.5rem;
    width: 2.5rem;
    position: fixed;
    bottom: 12rem;
    right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    opacity: 0.8;
    border: 1px solid $primary__theme_color;
    cursor: pointer;
    svg {
        width: 1rem !important;
        height: 1rem !important;
        path {
            fill: $primary__theme_color !important;
        }
    }
    #{$dark__mode__theme__selector} {
        background: $dark__mode__theme__grey__color !important;
    }

    @include media(">medium-screen") {
        display: none;
    }
}
