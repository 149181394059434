@import "assets/scss/master";
@import "assets/scss/_include-media-lib/include-media";

.branch__option {
    border-radius: 0.875rem;
    background-color: $light__theme_color;
    border: 0.063rem solid $border_highlight_color;
    cursor: pointer;
    color: $text__default_color;
    outline: none;
    font-size: 0.906rem;
    letter-spacing: 0.003rem;
    padding: 7px 0.75rem;
    transition: all 0.3s;
    height: fit-content;
    display: flex;

    #{$relaxed__mode__template__selector} {
        padding: 10px 37.25px;
        width: fit-content;
        font-size: 16px;
        line-height: 24px;
        border-radius: 100px;
    }

    @include media("<=medium-screen") {
        #{$relaxed__mode__template__selector} {
            display: block;
            width: 100%;
            font-size: 20px;
            line-height: 28px;
            padding: 14px 37.25px;
            text-align: center;
            border-radius: 100px;
            justify-content: center;
            gap: 20px;
            align-items: center;
        }
    }

    @include media("<=medium-small-screen") {
        #{$relaxed__mode__template__selector} {
            font-size: 18px;
            line-height: 24px;
        }
    }

    @include media("<=small-screen") {
        font-size: 18px !important;
    }

    #{$dark__mode__theme__selector} {
        color: $dark__mode__text__theme__color;
        background: $dark__mode__theme__grey__color;
        border: 0.125rem solid $dark__mode__theme__grey__color;
    }

    #{$white__mode__theme__selector} {
        color: $muted__theme_color_2;
        background: $white__mode__option__background;
        border: 0.125rem solid $white__mode__option__background;
    }

    &:hover {
        opacity: 0.5;
    }

    &:disabled {
        color: $text__default_color;
    }

    &.__fade_out {
        color: $option__fade_out;
        pointer-events: none;
    }
    &.active {
        border-color: $primary__theme_color;
        cursor: default;

        #{$white__mode__theme__selector} {
            background: $light__theme_color;
        }
    }

    &--hide-on-mobile {
        @include media("<=medium-screen") {
            display: none;
        }
    }
    &--loader {
        margin-left: 1rem !important;
        @include media("<=medium-screen") {
            margin: 0;
            margin-left: auto !important;
            margin-right: auto !important;
        }
    }
}
