@import "assets/scss/master";
@import "assets/scss/_include-media-lib/include-media";

.upload {
    margin-bottom: 0.938rem;
    width: 100%;

    &.no-label {
        width: fit-content;
        margin: 0;
    }
    
    span {
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        display: flex;
        align-items: center;

        #{$dark__mode__theme__selector} {
            color: $dark__mode__text__theme__color;
        }

        #{$white__mode__theme__selector} {
            color: $dark__mode__theme__grey__color;
        }
    }

    &--icons {
        display: flex;
        align-items: center;
        gap: 0.92rem;
        justify-content: center;
        height: 100%;

        &__label {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 14px;
            gap: 19px;
            width: 100%;
            cursor: pointer;
        }

        svg {
            cursor: pointer;

            path {
                fill: $dark__mode__theme__grey__color;

                #{$white__mode__theme__selector} {
                    fill: $dark__mode__theme__grey__color;
                }

                #{$dark__mode__theme__selector} {
                    fill: $light__theme_color;
                }
            }

            @include media("<=medium-screen") {
                height: 0.917rem;
                width: 0.917rem;
            }
        }
    }
}