@import "assets/scss/master";
@import "assets/scss/_include-media-lib/include-media";

.message__group {
    display: flex;
    align-items: flex-end;
    gap: 1rem;
    margin-bottom: 1.25rem;
    &.remove__margin {
        margin-bottom: 0;
    }
    &.sent {
        justify-content: flex-end;
    }
    &--sending {
        margin: unset !important;
        margin-right: 0rem !important;
    }
    &--error {
        padding: 0.5rem 1rem;
        padding-right: 1.25rem;
        border-radius: 0.5rem;
        border-radius: 0.5rem;
        color: #f25a68;
        cursor: pointer;

        #{$relaxed__mode__template__selector} {
            padding: 4px 10px;
            display: flex;
            align-items: center;
            gap: 8px;
            border-radius: 10px;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: -0.2px;
        }

        #{$dark__mode__theme__selector} {
            border: 1px solid #f25a68;
            color: #f25a68;
            background: $dark__mode__theme__background__color;
        }
    }
    &--content {
        width: 100%;
        display: flex;
        flex-direction: column;
        .sent & {
            align-items: flex-end;
        }
        .message__content {
            width: fit-content;
            max-width: 50%;
            font-size: 0.938rem;
            letter-spacing: 0.003rem;
            margin-bottom: 0.625rem;
            white-space: pre-wrap;
            .message {
                padding: 0.75rem 1rem 0.625rem;
                overflow-wrap: anywhere;
                p {
                    margin: 0 !important;
                }
                #{$relaxed__mode__template__selector} {
                    padding: 18px 24px;
                }
            }

            @include media("<=medium-screen") {
                font-size: 1rem;
                max-width: 90%;
                // #{$ios__device__selector} {
                //     font-size: 1.1rem;
                // }
                #{$relaxed__mode__template__selector} {
                    max-width: 100%;
                }
            }

            &.sent {
                @include media("<=medium-screen") {
                    #{$relaxed__mode__template__selector} {
                        font-size: 24px;
                        line-height: 32px;
                        border-radius: 1rem 1rem 1rem 0;
                    }
                }

                @include media("<=medium-small-screen") {
                    font-size: 18px;
                    line-height: 24px;
                    #{$relaxed__mode__template__selector} {
                        font-size: 18px;
                        line-height: 24px;
                    }
                }

                @include media("<=small-screen") {
                    font-size: 18px !important;
                }
            }

            &.received {
                color: $light__theme_color;
                background: $primary__theme_color;
                border-radius: 0.75rem 0.75rem 0.75rem 0;

                @include media("<=medium-screen") {
                    #{$relaxed__mode__template__selector} {
                        font-size: 24px;
                        line-height: 32px;
                        border-radius: 1rem 1rem 1rem 0;
                    }
                }

                @include media("<=medium-small-screen") {
                    font-size: 18px;
                    line-height: 24px;
                    #{$relaxed__mode__template__selector} {
                        font-size: 18px;
                        line-height: 24px;
                    }
                }

                @include media("<=small-screen") {
                    font-size: 18px !important;
                }
            }

            &.info__action {
                color: $text__default_color;
                background: $light__theme_color;
                border: 0.063rem solid $primary__theme_color;
                border-radius: 0.75rem 0.75rem 0.75rem 0;

                #{$dark__mode__theme__selector} {
                    border: none;
                    background: $dark__mode__theme__grey__color;
                }

                #{$white__mode__theme__selector} {
                    background: $white__mode__theme__grey__color;
                }

                @include media(">medium-screen") {
                    #{$relaxed__mode__template__selector} {
                        background: $primary__theme_color;
                        padding: 16px 24px;
                    }

                    #{$white__mode__theme__selector} {
                        background: $white__mode__background !important;
                    }
                }

                @include media("<=medium-screen") {
                    #{$relaxed__mode__template__selector} {
                        border-radius: 1rem 1rem 1rem 0;
                        padding: 18px 24px;
                        background: $primary__theme_color !important;
                    }

                    #{$white__mode__theme__selector} {
                        background: $white__mode__background !important;
                    }
                }

                //  @include media("<=medium-screen") {
                //     #{$relaxed__mode__template__selector} {
                //         font-size: 24px;
                //         line-height: 32px;
                //         border-radius: 1rem 1rem 1rem 0;
                //     }
                // }

                .message {
                    padding-top: 0.5rem;

                    #{$relaxed__mode__template__selector} {
                        padding: 0 0 0;
                    }

                    .action__message {
                        #{$dark__mode__theme__selector} {
                            color: $light__theme_color;
                        }

                        #{$white__mode__theme__selector} {
                            color: $text__default_color;
                        }

                        @include media("<=medium-screen") {
                            #{$relaxed__mode__template__selector} {
                                font-size: 18px;
                                line-height: 24px;
                            }

                            #{$white__mode__theme__selector} {
                                color: $dark__mode__theme__grey__color;
                            }
                        }

                        @include media("<=medium-small-screen") {
                            #{$relaxed__mode__template__selector} {
                                font-size: 16px;
                                line-height: 24px;
                            }
                        }

                        &.action__message__bold {
                            #{$dark__mode__theme__selector} {
                                color: $light__theme_color;
                            }

                            #{$white__mode__theme__selector} {
                                color: $text__default_color;
                            }

                            @include media("<=medium-screen") {
                                #{$relaxed__mode__template__selector} {
                                    font-size: 24px;
                                    line-height: 32px;
                                }

                                #{$white__mode__theme__selector} {
                                    color: $dark__mode__theme__grey__color;
                                }
                            }

                            @include media("<=medium-small-screen") {
                                #{$relaxed__mode__template__selector} {
                                    font-size: 18px;
                                    line-height: 24px;
                                }
                            }
                        }
                    }

                    .template__ratings {
                        @include media("<=medium-screen") {
                            #{$relaxed__mode__template__selector} {
                                .star-svg {
                                    width: 30px;
                                    height: 35px;
                                }
                            }
                        }
                    }
                }

                .action__header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0.75rem 1rem 0;

                    #{$relaxed__mode__template__selector} {
                        align-items: flex-start;
                        padding: 0;
                    }

                    .action__header__text__container {
                        display: flex;
                        align-items: baseline;
                        gap: 0.625rem;

                        .action__attention {
                            svg {
                                margin-top: -0.188rem;

                                #{$relaxed__mode__template__selector} {
                                    height: 22px;
                                    width: 22px;
                                }

                                @include media("<=medium-screen") {
                                    #{$relaxed__mode__template__selector} {
                                        height: 32px;
                                        width: 32px;
                                    }
                                }

                                path {
                                    fill: $primary__theme_color;
                                }
                            }
                        }

                        .action__header__text {
                            font-family: $primary_font_family--medium;
                            font-size: 0.938rem;
                            color: $text__default_color;
                            margin: 0 0 0.5rem;

                            #{$dark__mode__theme__selector} {
                                color: $light__theme_color;
                            }

                            #{$white__mode__theme__selector} {
                                color: $text__default_color;
                            }

                            @include media("<=medium-screen") {
                                #{$relaxed__mode__template__selector} {
                                    font-weight: 500;
                                    font-size: 24px;
                                    line-height: 32px;
                                }

                                #{$white__mode__theme__selector} {
                                    color: $dark__mode__theme__grey__color;
                                }
                            }
                            @include media("<=medium-small-screen") {
                                #{$relaxed__mode__template__selector} {
                                    font-size: 18px;
                                }
                            }
                        }
                    }

                    .remove__action {
                        svg {
                            #{$relaxed__mode__template__selector} {
                                height: 12px;
                                width: 12px;
                                cursor: pointer;
                            }

                            path {
                                #{$white__mode__theme__selector} {
                                    stroke: $muted__theme_color_2;
                                }
                            }

                            @include media("<=medium-screen") {
                                #{$relaxed__mode__template__selector} {
                                    height: 18px;
                                    width: 18px;
                                }

                                path {
                                    #{$white__mode__theme__selector} {
                                        stroke: $muted__theme_color_2;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &.input__needed {
                #{$relaxed__mode__template__selector} {
                    background: $dark__mode__desktop__chat__theme__color !important;
                }

                #{$white__mode__theme__selector} {
                    background: $white__mode__background !important;
                }
            }

            &.success__message {
                display: flex;
                border-radius: 16px 16px 16px 0px;

                border: 1px solid rgba(0, 102, 245, 0.2);

                #{$relaxed__mode__template__selector} {
                    border: 1px solid rgba(0, 102, 245, 0.2);
                }

                #{$dark__mode__theme__selector} {
                    background: $dark__mode__theme__grey__color;
                    border: unset;
                }

                #{$white__mode__theme__selector} {
                    background: $white__mode__background;
                    border: unset;
                }

                .icon__container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 18.33px;
                    background: rgba(0, 102, 245, 0.2);
                    border-radius: 1rem 0 0 0;

                    svg {
                        #{$relaxed__mode__template__selector} {
                            height: 22px;
                            width: 22px;
                        }

                        @include media("<=medium-screen") {
                            #{$relaxed__mode__template__selector} {
                                height: 32px;
                                width: 32px;
                            }
                        }
                    }
                }

                .success__message__content {
                    padding: 18px 1rem;

                    .success__message__title {
                        font-size: 0.938rem;
                        color: $text__default_color;
                        margin: 0 0 0.5rem;
                        font-weight: 500;

                        #{$dark__mode__theme__selector} {
                            color: $light__theme_color;
                        }

                        #{$white__mode__theme__selector} {
                            color: $dark__mode__theme__dark__color;
                        }

                        @include media("<=medium-screen") {
                            #{$relaxed__mode__template__selector} {
                                font-weight: 500;
                                font-size: 20px;
                                line-height: 28px;
                                margin: 0;
                            }
                        }

                        @include media("<=medium-small-screen") {
                            #{$relaxed__mode__template__selector} {
                                font-size: 18px;
                            }
                        }
                    }

                    .success__message__text {
                        font-size: 0.938rem;
                        letter-spacing: 0.003rem;
                        #{$dark__mode__theme__selector} {
                            color: $light__theme_color;
                        }

                        #{$white__mode__theme__selector} {
                            color: $dark__mode__theme__dark__color;
                        }
                        @include media("<=medium-screen") {
                            #{$relaxed__mode__template__selector} {
                                font-size: 18px;
                                line-height: 24px;
                                margin: 10px 0 0;
                            }
                        }

                        @include media("<=medium-small-screen") {
                            #{$relaxed__mode__template__selector} {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }

            &.sent {
                color: $light__gray__theme_color;
                background: $scroll__bar__track_color;
                border-radius: 0.75rem 0.75rem 0 0.75rem;
                text-align: left;

                #{$dark__mode__theme__selector} {
                    color: $light__theme_color;
                    background: $dark__mode__theme__grey__color;
                }

                @include media("<=medium-screen") {
                    #{$relaxed__mode__template__selector} {
                        font-size: 24px;
                        line-height: 32px;
                        border-radius: 1rem 1rem 0 1rem;
                    }

                    #{$white__mode__theme__selector} {
                        color: $dark__mode__theme__grey__color;
                        background: $white__mode__theme__grey__color;
                    }
                }

                @include media("<=medium-small-screen") {
                    font-size: 18px;
                    line-height: 24px;
                    #{$relaxed__mode__template__selector} {
                        font-size: 18px;
                        line-height: 24px;
                    }
                }

                @include media(">medium-screen") {
                    #{$relaxed__mode__template__selector} {
                        background: $dark__mode__desktop__chat__theme__color !important;
                    }

                    #{$white__mode__theme__selector} {
                        color: $black__theme_color;
                        background: $white__mode__background !important;
                    }
                }
            }

            &.grayed__out {
                color: $option__fade_out;
            }
        }
    }
}
