@import "assets/scss/master";
@import "assets/scss/_include-media-lib/include-media";

.options__container {
    #{$relaxed__mode__template__selector} {
        position: fixed;
        z-index: 1;
    }
}
.options__group {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    background: none;

    @include media(">medium-screen") {
        #{$relaxed__mode__template__selector} {
            display: flex;
            flex-wrap: wrap;
            padding: 40px 45px 65px;
            // padding: 40px 15px 50px;
            gap: 16px;
            position: fixed;
            left: 8.5%;
            transform: translate(-50%, 0);
            width: 83%;
            bottom: 0;
            z-index: 2;
            background: $dark__mode__theme__dark__color;
            animation: slide-in 0.8s normal forwards ease;

            @keyframes slide-in {
                0% {
                    transform: translateY(150px);
                }
                100% {
                    transform: translateY(0);
                }
            }
        }

        #{$white__mode__theme__selector} {
            background: $white__mode__options__background;
        }
    }

    @include media("<=medium-screen") {
        background: none;
        #{$relaxed__mode__template__selector} {
            display: flex;
            flex-direction: column;
            padding: 40px 45px 10px;
            gap: 16px;
            position: fixed;
            width: 100%;
            left: 0;
            bottom: 0;
            z-index: 2;
            background: $dark__mode__theme__dark__color;
            animation: slide-in 0.8s normal forwards ease;

            @keyframes slide-in {
                0% {
                    transform: translateY(150px);
                }
                100% {
                    transform: translateY(0);
                }
            }
        }

        #{$white__mode__theme__selector} {
            background: $white__mode__options__background;
        }
    }

    @include media("<=medium-small-screen") {
        #{$relaxed__mode__template__selector} {
            padding: 40px 15px 19px;
        }
    }
}

.options__group__investigate {
    #{$relaxed__mode__template__selector} {
        @include media("<=medium-screen") {
            max-height: 40vh !important;
            display: flex !important;
            overflow: scroll !important;
            flex-wrap: unset !important;
            padding: 40px 15px 60px;
        }
    }
}

.options__group__error {
    color: $danger__theme_color;
    position: fixed;
    z-index: 100;
    width: 100%;
    margin-top: 11.5rem;
    font-size: 14px;
    margin-top: 10.5rem;
    font-size: 14px;
    left: 0;
    bottom: 20px;
    z-index: 10000;
    display: flex;
    justify-content: center;

    @include media("<=medium-screen") {
        bottom: 10px;
    }
}

.options__footer {
    bottom: 10px !important;
    position: fixed;
    z-index: 100;
    padding: 8px;
    width: 80%;
    left: auto;
    right: auto;
    @include media("<=medium-screen") {
        bottom: 0px !important;
        width: 100%;
        left: 0;
    }
}
