@import "assets/scss/master";

.new-message-indicator {
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: fixed;
    bottom: 11rem;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
    background: #f3f5f7;
    cursor: default;
    #{$dark__mode__theme__selector} {
        background: $dark__mode__theme__dark__color;
    }
    span {
        color: var(--text-body-text, #696d8c);
        font-family: Gelion;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__text__theme__color;
        }
    }
}
