@import "assets/scss/master";
@import "assets/scss/_include-media-lib/include-media";

.info__box {
    height: 1.875rem;
    border: 0.063rem solid $border__theme_color;
    border-radius: 0.375rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0.625rem;
    .ticket__status span {
        color: $muted__theme_color_2;
        font-family: $primary_font_family;
        font-size: 0.875rem;
    }
    &.straight {
        height: 2.25rem;
        margin: 0 0.5rem;
        @include media("<=medium-screen") {
            margin: 0;
        }
    }
}