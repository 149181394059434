@import "assets/scss/animate/animate.scss";

.shine__loader {
    border-radius: 0px;
    width: 7.5rem;
    height: 1.625rem;
    overflow: hidden;
    position: relative;
    animation: shine-animation 0.5s infinite cubic-bezier(0.4, 0, 1, 1);
    -webkit-animation: shine-animation 0.5s infinite cubic-bezier(0.4, 0, 1, 1);
}

.shiny__background {
    @include background-gradient;
    animation: shine-animation 0.5s infinite cubic-bezier(0.4, 0, 1, 1);
    -webkit-animation: shine-animation 0.5s infinite cubic-bezier(0.4, 0, 1, 1);
}

.shiny__line {
    width: 7.5rem;
    height: 1.25rem;
    border-radius: 0.188rem;
    @include background-gradient;
    animation: shine-lines $animation-duration infinite linear;
}
