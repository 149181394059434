@import "assets/scss/master.scss";
@import "assets/scss/_include-media-lib/include-media";

.in-app-chat-interface {
    // height: 100% !important;
    padding: 20px 120px 0px 120px;

    height: 100vh !important;

    @include media("<=large-screen") {
        padding: 20px 80px 0px 80px;
    }

    @include media("<=medium-screen") {
        padding: 0;
        padding-top: 30px;
    }

    background: $light__theme_color !important;
    #{$dark__mode__theme__selector} {
        background-color: $dark__mode__theme__background__color !important;
    }

    &__container {
        height: 100%;

        background: $light__theme_color !important;
        border: 1px solid $white__mode__border__color !important;
        border-radius: 25px 25px 0px 0px;

        #{$dark__mode__theme__selector} {
            border: 1px solid $dark__mode__desktop__chat__theme__color !important;
            background-color: $dark__mode__theme__background__color !important;
            @include media("<=medium-screen") {
                border: none !important;
            }
        }

        // background-color: yellow !important;
        @include media("<=medium-screen") {
            border: none !important;
        }
    }

    &__body {
        height: calc(100% - 120px);
        margin-top: 60px;

        background: $light__theme_color !important;
        #{$dark__mode__theme__selector} {
            background-color: $dark__mode__theme__background__color !important;
        }

        @include media("<=medium-screen") {
            margin-top: 50px;
        }
    }

    .message__content {
        @include media("<=small-screen") {
            max-width: 90% !important;
        }
    }

    .options__container {
        max-width: 50%;
        margin-left: 32px;
        @include media("<=small-screen") {
            max-width: 90% !important;
        }
    }

    @include media("<=small-screen") {
        border: none !important;
        width: 100% !important;
    }

    &__header {
        height: 60px;

        display: flex;

        align-items: center;

        justify-content: center;

        border-radius: 25px 25px 0px 0px;

        position: fixed;

        z-index: 10000;

        @include media("<=medium-screen") {
            height: 50px;
            margin-left: 20px;
            margin-right: 20px;
            display: none;
        }

        right: 121px;
        left: 121px;
        z-index: 100000;
        @include media("<=large-screen") {
            left: 81px;
            right: 81px;
        }

        border-bottom: 0.063px solid $white__mode__border__color !important;

        #{$dark__mode__theme__selector} {
            background: transparent;
            border-bottom: 0.063px solid $dark__mode__theme__grey__color !important;

            @include media("<=small-screen") {
                background: $dark__mode__theme__grey__color;
                border-radius: 20px 20px 20px 20px;
                // border: 0.063px solid $dark__mode__theme__grey__color !important;
            }
        }

        #{$white__mode__theme__selector} {
            background: $light__theme_color !important;
            border: 1px solid $white__mode__border__color;
        }

        &__toggle {
            width: fit-content;
            margin-left: auto;
            margin-right: 20px;
        }

        .ticket-header__icon {
            display: block;
            cursor: pointer;

            // #{$relaxed__mode__template__selector} {
            border: none;
            padding: 0;

            svg {
                width: 20px;
            }
            // }
        }

        .company__logo {
            width: 36px;
            height: 36px;
            border-radius: 100%;
            object-fit: contain;
            cursor: pointer;
            margin-left: auto;
            margin-right: 8px;
            @include media("<=medium-screen") {
                max-width: 26px;
                height: 26px;
            }
        }

        span {
            font-weight: 600;
            font-size: 20px;
            line-height: 26px;
            text-transform: capitalize;
            cursor: pointer;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__text__theme__color;
            }

            #{$white__mode__theme__selector} {
                color: $dark__mode__theme__grey__color;
            }

            @include media("<=small-screen") {
                font-weight: 550;
                font-size: 16px;
                font-family: "Gelion-Medium" !important;
            }
        }
    }

    &--something-else {
        color: $primary__theme_color !important;

        #{$dark__mode__theme__selector} {
            background: $dark__mode__theme__background__color !important;
            border: 1px solid rgba(131, 133, 137, 0.2) !important;
            &:hover {
                background-color: unset;
                color: $light__theme_color !important;
            }
        }

        #{$white__mode__theme__selector} {
            background-color: transparent !important;
            border: 1px solid rgba(131, 133, 137, 0.2) !important;
        }

        &.is-existing {
            // border:1px solid $primary__theme_color !important;
            color: $primary__theme_color !important;
        }

        &:hover {
            background-color: unset;
            color: unset !important;
        }
    }

    &__content {
        padding: 6rem 20px;
        padding-bottom: 6rem;
        overflow-y: scroll;
        height: 100%;
        background: $light__theme_color !important;
        #{$dark__mode__theme__selector} {
            background-color: $dark__mode__theme__background__color !important;
        }
        @include media("<=small-screen") {
            padding-top: 3rem;
            padding-bottom: 8rem;
        }
    }

    &__footer {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        bottom: 21px;
        bottom: 0px;
        right: 121px;
        left: 121px;
        height: 60px;
        z-index: 100000;
        border-radius: 0px 0px 25px 25px;
        #{$dark__mode__theme__selector} {
            background-color: $dark__mode__theme__background__color !important;
        }
        @include media("<=large-screen") {
            left: 81px;
            right: 81px;
        }

        @include media("<=medium-screen") {
            left: 0;
            right: 0;
            bottom: 0;
            height: 70px;
            border-radius: 0;
        }

        border-top: 0.063px solid $white__mode__border__color !important;
        #{$dark__mode__theme__selector} {
            background-color: $dark__mode__theme__background__color;
            border-top: 0.063px solid $dark__mode__theme__grey__color !important;
        }

        #{$white__mode__theme__selector} {
            background: $light__theme_color !important;
            border: 1px solid $white__mode__border__color;
        }
    }

    .powered__by {
        z-index: 100000 !important;
        display: block !important;
        width: fit-content !important;

        position: unset !important;
        height: fit-content !important;
        z-index: 10000000 !important;
        bottom: unset !important;
    }
}
