@import "assets/scss/master";

.skeleton-loader {
    width: 100%;
    overflow: hidden;
    .skeleton-box {
        display: inline-block;
        height: 100px;
        position: relative;
        overflow: hidden;
        background-color: $white__mode__option__background;
        width: 100%;
        #{$dark__mode__theme__selector} {
            background: $dark__mode__theme__dark__color;
        }
        &::after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            transform: translateX(-100%);
            background-image: linear-gradient(
                90deg,
                rgba(#fff, 0) 0,
                rgba(#fff, 0.2) 20%,
                rgba(#fff, 0.5) 60%,
                rgba(#fff, 0)
            );
            #{$dark__mode__theme__selector} {
                background-image: linear-gradient(
                    90deg,
                    rgba(45, 51, 58, 0) 0,
                    rgba(45, 51, 58, 0.3) 20%,
                    rgba(45, 51, 58, 0.7) 60%,
                    rgba(45, 51, 58, 0)
                );
            }
            animation: skeleton 2s infinite;
            content: "";
        }

        @keyframes skeleton {
            100% {
                transform: translateX(100%);
            }
        }
    }
}
