@import "assets/scss/master";

.chat__header__banner__message__wrapper {
    margin-top: 97px;
}

.chat__header__banner__workmode__wrapper {
    display: none;
    .banner__message {
        padding: 8px 20px !important;
        height: 33px;
        border: 1px solid $primary__theme_color;
        border-radius: 8px;
        width: 210px;
        display: flex !important;

        svg {
            width: 11.67px !important;
            height: 11.67px !important;
            margin-top: -3px !important;
        }

        &__text {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            width: 100% !important;
        }

        .close__banner__message {
            display: none;
        }
    }
}
