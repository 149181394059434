@import "assets/scss/master";

.typing__indicator__group {
    display: flex;
    align-items: flex-end;
    gap: 1rem;
    margin-bottom: 1.25rem;
  
    .typing__content {
        width: fit-content;
        max-width: 50%;
        font-size: 0.938rem;
        letter-spacing: 0.05px;
        margin-bottom: 0.625rem;
    
        &.received {
            color: $light__theme_color;
            background: $primary__theme_color;
            border-radius: 0.75rem 0.75rem 0.75rem 0px;
            padding: 0.75rem 1rem 0.625rem;
        }
    }
}

.typing-indicator {
    $ti-color-bg: #e6e7ed;
    animation: 1s bulge infinite ease-out;
    padding-top: 0.313rem;
    padding-bottom: 0.625rem;
    span {
        height: 0.313rem;
        width: 0.313rem;
        float: left;
        margin: 0 0.313rem;
        background-color: $light__theme_color;
        display: block;
        border-radius: 50%;
        opacity: 0.4;
        @for $i from 1 through 3 {
            &:nth-of-type(#{$i}) {
                animation: 1s blink infinite ($i * 0.3333s);
            }
        }
    }
}

@keyframes blink {
    50% {
        opacity: 1;
    }
}

@keyframes bulge {
    50% {
        transform: scale(1.05);
    }
}
