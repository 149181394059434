@import "assets/scss/master";

.content--media {
    cursor: pointer;
    margin: 0.5rem 0;

    &.sent {
        border-radius: 0.5rem 0.5rem 0 0.5rem;
    }

    &.received {
        border-radius: 0.5rem 0.5rem 0.5rem 0px;
    }

    &.img {
        object-fit: cover;
        border: 1px solid $primary__theme_color;
        #{$relaxed__mode__template__selector} {
            border: none;
        }

        &.mobile {
            aspect-ratio: 3 / 2;
            max-width: 100%;
        }

        &.desktop {
            width: 440px;
            aspect-ratio: 16 / 9;
        }

        &.img-orientation {
            object-fit: contain;

            #{$relaxed__mode__template__selector} {
                object-fit: cover;
            }
        }
    }
    &.video {
        height: auto;
        width: fit-content;
        max-width: 100%;
    }
}

.content--document {
    padding: 0.75rem 0.688rem;
    background: rgba(217, 217, 217, 0.6);
    border-radius: 0.25rem 0.25rem 0 0;
    display: flex;
    gap: 0.453rem;
    width: 100%;
    align-items: center;
    cursor: pointer;

    svg {
        width: 1.25rem;
        height: 1.5rem;
    }

    &.sent {
        border-radius: 0.5rem 0.5rem 0 0.5rem;
    }

    &.received {
        border-radius: 0.5rem 0.5rem 0.5rem 0px;
    }

    .details {
        display: flex;
        flex-direction: column;

        p {
            font-size: 0.75rem;
            line-height: 1rem;
            display: flex;
            align-items: center;
            margin: 0;
        }

        span {
            font-size: 8px;
            line-height: 0.75rem;
            text-transform: uppercase;
            text-align: left;
        }
    }
}
