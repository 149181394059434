@import "assets/scss/master";

.fetching__error {
    #{$dark__mode__theme__selector} {
        color: $dark__mode__text__theme__color;
    }

    #{$white__mode__theme__selector} {
        color: $dark__mode__theme__grey__color;
    }

    &__retry {
        color: $light__blue__theme__color;
        margin-left: 10px;
        cursor: pointer;
    }
}