@import "assets/scss/master";
@import "assets/scss/_include-media-lib/include-media";

.ticket-closure-modal {
    text-align: center;
    padding: 24px 31px;
    width: 300px !important;

    &.relaxed__template__ticket-closure-modal {
        padding: 40px;
        width: 100% !important;
    }

    &__rating__wrapper {
        #{$relaxed__mode__template__selector} {
            display: flex;
            flex-direction: column-reverse;
        }
    }

    &__rating {
        margin-bottom: 18px;
        margin-left: auto !important;
        margin-right: auto !important;
        width: fit-content !important;
        text-align: center !important;
        span {
            margin-left: 0.2rem;
        }
    }

    &__text {
        text-align: center;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #060213;

        &.relaxed__template__modal__text {
            font-size: 20px;
            line-height: 28px;
        }

        @include media("<=small-screen") {
            font-size: 18px !important;
        }
        
        &.dark__mode__modal__text {
            color: $light__theme_color;
        }
    }
}
