@import "assets/scss/master";
@import "assets/scss/_include-media-lib/include-media";

.in-app-chat-loader {
    padding: 6rem 20px;

    height: fit-content;
    @include media("<=medium-screen") {
    }

    .message {
        width: 400px !important;
        padding: 0 !important;
        height: 80px !important;
        border-radius: 10px !important;

        @include media("<=medium-screen") {
            width: 100% !important;
            height: 100px !important;
        }
    }

    .skeleton-loader {
        width: 100% !important;
        border-radius: 10px 10px 10px 0px !important;
        height: 100% !important;
        .skeleton-box {
            height: 100% !important;
        }
    }

    .options__container {
        background-color: transparent !important;
        .option__loader {
            border-radius: 0.875rem;
            border: 0.063rem solid $border_highlight_color;
            cursor: pointer;
            transition: all 0.3s;
            height: 36px;

            #{$dark__mode__theme__selector} {
                color: $dark__mode__text__theme__color;
                border: 0.125rem solid $dark__mode__theme__grey__color;
            }

            #{$white__mode__theme__selector} {
                color: $muted__theme_color_2;
                background: $white__mode__option__background;
                border: 0.125rem solid $white__mode__option__background;
            }
        }

        .skeleton-loader {
            width: 100% !important;
            border-radius: 10px !important;
            height: 100% !important;
            .skeleton-box {
                width: 140px !important;
                #{$dark__mode__theme__selector} {
                    background: $dark__mode__theme__grey__color !important;
                }
            }
        }
    }
}
