@import "assets/scss/master";
@import "assets/scss/_include-media-lib/include-media";

.settings__toggle__modal__content {
    width: 350px !important;
    border: none !important;
    border-radius: 20px;
    padding: 12px 20px;

    @include media("<=small-screen") {
        width: 100% !important;
    }

    &.dark__mode__settings__toggle {
        background: $dark__mode__theme__dark__color;
    }

    .settings__toggle__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
            font-weight: 600;
            font-size: 18px;
            line-height: 26px;

            &.dark__mode__toggle__header {
                color: $dark__mode__text__theme__color;
            }

            &.white__mode__toggle__header {
                color: $dark__mode__desktop__chat__theme__color;
            }
        }

        .toggle__settings__modal {
            cursor: pointer;

            &.dark__mode__toggle__settings {
                svg {
                    path {
                        stroke:  $light__theme_color;
                    }
                }
            }

            &.white__mode__toggle__settings {
                svg {
                    path {
                        stroke:  $dark__mode__theme__grey__color;
                    }
                }
            }
        }
    }

    .settings__items {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 10px;

        .settings__item {
            font-size: 20px;
            line-height: 24px;
            list-style: none;
            padding: 24px;
            width: 100%;
            cursor: pointer;
            display: flex;
            justify-content: space-between;

            &.dark__mode__settings__item {
                color: $light__theme_color;
                border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            }

            &.white__mode__settings__item  {
                color: $dark__mode__theme__grey__color;
                border-bottom: 1px solid $dark__mode__theme__grey__color;
            }

            .settings__item__sun__icon {
                &.dark__mode__settings__item__sun__icon {
                    svg {
                
                        g {
                          path {
                            stroke: $light__theme_color;
                          }
                        }
                      }
                }
    
                &.white__mode__settings__item__sun__icon {
                    svg {
                
                        g {
                          path {
                            stroke: $dark__mode__theme__grey__color;
                          }
                        }
                      }
                }
            }

            .settings__item__cancel__icon {
                svg {
                    width: 19px;
                    height: 19px;
                }

                &.dark__mode__settings__item__cancel__icon {
                   svg {
                    
                        path {
                            stroke: $light__theme_color;
                        }
                }
                }
    
                &.white__mode__settings__item__cancel__icon {
                    svg {
                    
                        path {
                            stroke: $dark__mode__theme__grey__color;
                        }
                }
                }
            }
        }
    }
}