@import "assets/scss/master";
@import "assets/scss/_include-media-lib/include-media";

.action-message-option {
    width: fit-content;

    #{$relaxed__mode__template__selector} {
        padding: 10px 37.25px;
        width: fit-content;
        font-size: 16px;
        line-height: 24px;
        border-radius: 100px;
    }
    
    @include media("<=medium-screen") {
        #{$relaxed__mode__template__selector} {
            width: 100%;
            font-size: 20px;
            line-height: 24px;
            padding: 14px 37.25px;
            text-align: center;
            border-radius: 100px;
        }
    }

    @include media("<=medium-small-screen") {
        #{$relaxed__mode__template__selector} {
            font-size: 12px;
            line-height: 6px;
        }
    }

    #{$dark__mode__theme__selector} {
        color: $dark__mode__text__theme__color;
        background: $dark__mode__theme__grey__color;
        border: 0.125rem solid $dark__mode__theme__grey__color;
    }

    #{$white__mode__theme__selector} {
        color: $muted__theme_color_2;
        background: $white__mode__option__background;
        border: none;
    }
}
