@import "assets/scss/master";
@import "assets/scss/_include-media-lib/include-media";

.chat__interface {
    @include media(">medium-screen") {
        #{$relaxed__mode__template__selector} {
            background-color: $dark__mode__theme__grey__color;
            border-radius: 20px 20px 0px 0px;
            margin-top: 9px;
        }       

        #{$white__mode__theme__selector} {
            background-color: $light__theme_color;
            border: 1px solid $white__mode__border__color;
        }
    }

    // @include media("<=medium-screen") {
    //     #{$relaxed__mode__template__selector} {
    //         margin-top: 28%;
    //     }       
    // }
}

.live-chat-input__add-email {
    display: none;
    @include media("<=medium-screen") {
        // display: block;

        // #{$relaxed__mode__template__selector} {
            display: none;
        // }   
    }
}
