@import "assets/scss/master";
@import "assets/scss/_include-media-lib/include-media";

.media-display-loader {
    // width: 440px;
    background-color: $muted__theme_color !important;
    margin-bottom: 10px;
    border-radius: 0.5rem 0.5rem 0.5rem 0px;
    border: 1px solid $primary__theme_color;
    @include media(">medium-screen") {
        max-width: 440px !important;
        min-width: 400px !important;
        height: 246px !important;
    }

    @include media("<=medium-screen") {
        // min-width: 275px !important;
        max-height: 200px !important;
        // width: 100% !important;
        // height: 100% !important;
    }

    // position: relative;
    // overflow: hidden;

    // &::after {
    //     position: absolute;
    //     top: 0;
    //     right: 0;
    //     bottom: 0;
    //     left: 0;
    //     transform: translateX(-100%);
    //     background-image: linear-gradient(
    //         90deg,
    //         rgba($light__theme_color, 0) 0,
    //         rgba($light__theme_color, 0.2) 20%,
    //         rgba($light__theme_color, 0.5) 60%,
    //         rgba($light__theme_color, 0)
    //     );
    //     animation: shimmer 2s infinite;
    //     content: "";
    // }

    // @keyframes shimmer {
    //     100% {
    //         transform: translateX(100%);
    //     }
    // }
}
