@import "assets/scss/master";

.powered__by {
    display: flex;
    justify-content: center;
    padding: 0.188rem 0;
    align-items: center;
    background-color: $light__theme_color;
    // the width was set here to avoid the powered by footer overlay a parent border line
    width: calc(100% - 6px) !important;
    #{$dark__mode__theme__selector} {
        background-color: $dark__mode__theme__background__color;
    }

    #{$relaxed__mode__template__selector} {
        background-color: $dark__mode__theme__dark__color !important;
    }

    #{$white__mode__theme__selector} {
        background-color: $white__mode__background !important;
    }

    &.white__background {
        #{$white__mode__theme__selector} {
            background-color: $white__mode__options__background !important;
        }
    }
}