@import "./_master";
@import "assets/scss/_include-media-lib/include-media";

html {
    position: relative;
    height: 100% !important;
}

html,
body {
    font-family: "Gelion";
    color: #000000;
    max-width: 100%;
    height: 100%;
    overflow: hidden;
    // height: 100vh;

    @include media("<=large-screen") {
        height: 100%;
        // position: absolute;
        width: 100%;
    }
}

// ::-webkit-scrollbar {
//   width: 0 !important;  /* Remove scrollbar space */
//   background: transparent;  /* Optional: just make scrollbar invisible */
// }

.text-right {
    text-align: right;
}

#__next,
main {
    height: 100%;
}
.page {
    height: 100%;
}

.cursor-pointer {
    cursor: pointer;
}

.fw-medium {
    font-weight: 500;
    font-family: $primary_font_family--medium;
}

.fw-bold {
    font-family: $primary_font_family--semi-bold;
}

.fw-bolder {
    font-family: $primary_font_family--bold;
}

.w-60 {
    width: 60%;
}

.w-120 {
    width: 120%;
}

.text-neutral {
    color: #23262f;
}

.flex-gap {
    gap: 10px;
    &--1 {
        gap: 8px;
    }
    &--2 {
        gap: 12px;
    }
    &--3 {
        gap: 16px;
    }
    &--4 {
        gap: 24px;
    }
}

.w-0 {
    width: 0;
}

.h-0 {
    height: 0;
}

h1.no-content {
    font-size: 20px;
}

.no-wrap {
    white-space: nowrap;
}

.hidden {
    visibility: hidden;
}

.visible {
    visibility: visible;
}

.dot {
    content: "";
    width: 12px;
    height: 12px;
    border-radius: 100%;
}

.custom-badge {
    border-radius: 100%;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    width: 28px;
    height: 28px;
    margin-left: 8px;

    &.custom-badge--black {
        background-color: $black__theme_color;
        color: #fff;
    }

    &.custom-badge--light {
        background-color: #f3f4f4;
        color: $black__theme_color;
    }
}

.toast {
    &:not(.show) {
        display: block;
    }
}

.error {
    border: 1px solid red !important;
}

.flex__between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.border {
    &-primary {
        border-color: $primary__theme_color !important;
    }
}

.bg {
    &-primary {
        background: $primary__theme_color !important;
    }
}

.custom-scroll {
    &::-webkit-scrollbar {
        display: inline;
        width: 5px !important;
    }
    &::-webkit-scrollbar-track {
        background: #fafafc !important;
        border-radius: 3px !important;
        #{$dark__mode__theme__selector} {
            background: $dark__mode__theme__background__color !important;
        }
    }
    &::-webkit-scrollbar-thumb {
        background: #e6e6ed;
        border-radius: 3px;
        #{$dark__mode__theme__selector} {
            background: $dark__mode__theme__grey__color !important;
        }
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #4d4b4b;
    }
}
