@import "assets/scss/master.scss";
@import "assets/scss/_include-media-lib/include-media";

.email__input {
    // #{$relaxed__mode__template__selector} {
        padding: 23px 24px;
    // }

    @include media("<=medium-screen") {
        // #{$relaxed__mode__template__selector} {
            font-size: 1rem;
            line-height: 24px;
            padding: 1rem 18px;
        // }
    }

    #{$dark__mode__theme__selector} {
        color: $dark__mode__text__theme__color;
        background: $dark__mode__theme__dark__color;
        border: 1px solid $dark__mode__theme__dark__color !important;
    }

    &:focus {
        // #{$relaxed__mode__template__selector} {
            border-radius: 10px;
        // }

        #{$dark__mode__theme__selector} {
            color: $dark__mode__text__theme__color;
            background: $dark__mode__theme__dark__color;
        }
    }
}

.submit__email {
    // #{$relaxed__mode__template__selector} {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    // }
}

.info__section {
    display: flex;
    align-items: baseline;

    @include media(">medium-screen") {
        display: none;
    }

    svg {
        margin-right: 10px;
        margin-bottom: 4px;

        #{$dark__mode__theme__selector} {
            path {
                fill: $yellow__theme__color;
            }
        }
    }

    p {
        font-size: 14px;
        line-height: 22px;
        color: $black__theme_color;
        margin: 0;

        // #{$relaxed__mode__template__selector} {
            font-size: 16px;
            line-height: 24px;
        // }

        #{$dark__mode__theme__selector} {
            color: $light__theme_color;
        }
    }
}
