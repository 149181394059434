@import "assets/scss/master";
@import "assets/scss/_include-media-lib/include-media";

#authTickets {
    padding: 0px 20px;

    top: 0;
    z-index: -1;
    bottom: 0;
    padding-top: 90px;

    .btn {
        @include media("<=medium-screen") {
            width: 100% !important;
        }
    }

    &.show-mobile-menu {
        display: block !important;
        position: fixed;
        height: calc(100% - 58px);
        background: $light__theme_color;
        bottom: 0;
        width: 100%;
        left: 0;

        @include media("<=medium-screen") {
            // height: calc(100% - 90px);
            height: 100%;
            #{$relaxed__mode__template__selector} {
                // height: calc(100% - 97px);
                padding: none;
            }
        }

        #{$dark__mode__theme__selector} {
            background: $dark__mode__theme__background__color;
        }

        .relaxed__customer__ticket__loader {
            height: 90vh;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .customer__tickets--container,
        .customer__ticket {
            width: 100% !important;
        }

        .customer__tickets--container {
            height: 60%;
            margin: 0;
            margin-bottom: 2rem;
            overflow-y: auto;

            &::-webkit-scrollbar {
                border-radius: 10px;
                width: 3px;
            }
            &::-webkit-scrollbar-thumb {
                background: rgba(0, 0, 0, 0.1);
            }

            @include media("<=medium-screen") {
                padding-top: 20px;
                padding-bottom: 40px;
                max-height: 60%;
                height: fit-content;
                #{$relaxed__mode__template__selector} {
                    padding-top: 20px;
                    // padding-bottom: 0px;
                }
            }

            .customer__ticket {
                padding: 1.125rem 2.063rem;
                flex-shrink: 0;
                display: flex;
                gap: 12px;
                align-items: center;
                border: 0;

                justify-content: space-between;
                #{$relaxed__mode__template__selector} {
                    justify-content: unset;
                }

                .agent__name {
                    font-size: 1.25rem;
                    font-family: $primary_font_family--medium;
                    margin-left: 0;
                }

                &:not(:last-child) {
                    border-bottom: 0.063rem solid $border__theme_color;

                    #{$dark__mode__theme__selector} {
                        border-bottom: 0.063rem solid
                            $dark__mode__theme__grey__color;
                    }
                }

                @include media("<=medium-screen") {
                    border-bottom: 0.063rem solid $border__theme_color;

                    background-color: transparent;
                    border: 1px solid #ecebf5;
                    border-radius: 16px;

                    #{$dark__mode__theme__selector} {
                        border: unset;
                        background-color: $dark__mode__theme__grey__color;
                        border: 0.063rem solid $dark__mode__theme__grey__color;
                    }
                }
            }
        }
        .customer__tickets--container {
            flex-direction: column;

            @include media("<=medium-screen") {
                gap: 1rem;
                width: 100% !important;
                margin: auto;
                #{$relaxed__mode__template__selector} {
                    gap: 1rem;
                    width: 100% !important;
                    margin: auto;
                }
            }
        }
    }
}

.customer__ticket {
    padding-top: 0.625rem;
    padding-bottom: 0.75rem;
    padding-left: 1.813rem;
    border-left: 0.063rem solid $border__theme_color;
    width: 13.75rem;
    cursor: pointer;
    position: relative;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;

    display: flex;
    gap: 12px;
    align-items: center;
    border-radius: 10px;
    padding: 6px 16px;

    height: 50px !important;

    #{$relaxed__mode__template__selector} {
        display: flex;
        gap: 12px;
        align-items: center;
        border-radius: 10px;
        padding: 6px 16px;
    }

    background: $white__mode__background;

    #{$dark__mode__theme__selector} {
        border: 0.063rem solid $dark__mode__theme__grey__color;
        background: $dark__mode__desktop__ticket__background;
    }

    #{$white__mode__theme__selector} {
        background: $white__mode__background;
    }

    @include media("<=medium-screen") {
        padding: 12px 16px !important;
        border-radius: 16px;

        height: unset !important;
        &::before {
            display: none;
        }

        background-color: $white__mode__theme__grey__color;

        #{$white__mode__theme__selector} {
            background-color: $white__mode__theme__grey__color !important;
        }

        #{$relaxed__mode__template__selector} {
            padding: 12px 16px !important;
            background-color: $white__mode__theme__grey__color !important;
            border-radius: 16px;
            &::before {
                display: none;
            }
        }

        #{$dark__mode__theme__selector} {
            background: $dark__mode__theme__grey__color !important;
        }

        &.workmode {
            #{$dark__mode__theme__selector} {
                background: transparent !important;
            }
        }
    }

    // &:last-child {
    //     // border-right: 0.063rem solid $border__theme_color;

    //     // #{$dark__mode__theme__selector} {
    //     //     border-right: 0.063rem solid $dark__mode__theme__grey__color;
    //     // }
    // }

    &::before {
        content: "";
        position: absolute;
        display: block;
        left: 0;
        bottom: 0;
        width: 0%;
        height: 0.063rem;
        background: $primary__theme_color;
        transition: all 1s;
    }
    &.closed {
        opacity: 0.5;
    }
    &.active {
        border: 1px solid $primary__theme_color;

        &::before {
            display: none;
        }

        #{$relaxed__mode__template__selector} {
            border: 1px solid $primary__theme_color;

            &::before {
                display: none;
            }
        }

        @include media("<=medium-screen") {
            border: 1px solid $primary__theme_color !important;
            #{$relaxed__mode__template__selector} {
                border: 1px solid $primary__theme_color !important;

                &::before {
                    display: none;
                }
            }
        }

        &::before {
            width: 100%;
        }

        .close__ticket {
            display: block !important;
        }
    }

    .close__ticket {
        position: absolute;
        top: 0.625rem;
        right: 1.25rem;
        display: none !important;
        :hover {
            opacity: 0.8;
        }
    }

    .ticket__details {
        display: flex;
        flex-direction: column-reverse;

        #{$relaxed__mode__template__selector} {
            display: flex;
            flex-direction: column-reverse;
        }

        @include media("<=medium-screen") {
            display: flex;
            flex-direction: column-reverse;
            width: 66%;
            #{$relaxed__mode__template__selector} {
                width: 66%;
            }
        }

        .ticket__last__message {
            font-size: 14px;
            line-height: 18px;
            width: 195px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            p {
                margin: 0;
            }

            #{$relaxed__mode__template__selector} {
                font-size: 14px;
                line-height: 18px;
                width: 135px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }

            #{$dark__mode__theme__selector} {
                color: $option__change__theme__color;
            }

            #{$white__mode__theme__selector} {
                color: $muted__theme_color_2;
            }

            @include media("<=medium-screen") {
                font-size: 18px;
                line-height: 26px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                #{$relaxed__mode__template__selector} {
                    font-size: 18px;
                    line-height: 26px;
                    width: 100%;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }

                #{$white__mode__theme__selector} {
                    color: $option__change__theme__color;
                    opacity: 0.7;
                }

                &.active {
                    #{$white__mode__theme__selector} {
                        color: $dark__mode__theme__grey__color;
                    }
                }
            }

            &.active {
                #{$dark__mode__theme__selector} {
                    color: $light__theme_color;
                }

                #{$white__mode__theme__selector} {
                    color: $dark__mode__theme__grey__color;
                }
            }
        }

        .agent__name {
            font-size: 1rem;
            margin-left: 1.125rem;
            color: $text__default_color;
            margin-bottom: 0.313rem;
            text-transform: capitalize;

            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            margin: 0;

            #{$relaxed__mode__template__selector} {
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                margin: 0;
            }

            @include media("<=medium-screen") {
                font-size: 20px;
                line-height: 28px;
                #{$relaxed__mode__template__selector} {
                    font-size: 20px;
                    line-height: 28px;
                }

                #{$white__mode__theme__selector} {
                    color: $dark__mode__theme__grey__color;
                }
            }

            #{$dark__mode__theme__selector} {
                color: $light__theme_color;
            }
        }
    }

    .agent__pic--small {
        img {
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 50%;
        }
    }

    .ticket__metadata {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.1rem;
        margin-left: auto;
        #{$relaxed__mode__template__selector} {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 0.1rem;
        }

        @include media("<=medium-screen") {
            gap: 0.5rem;
            #{$relaxed__mode__template__selector} {
                gap: 0.5rem;
            }
        }

        .ticket__timestamp {
            font-size: 14px;
            line-height: 18px;
            color: $option__change__theme__color;

            #{$relaxed__mode__template__selector} {
                font-size: 14px;
                line-height: 18px;
                color: $option__change__theme__color;
            }

            @include media("<=medium-screen") {
                font-size: 14px;
                line-height: 22px;
                #{$relaxed__mode__template__selector} {
                    font-size: 14px;
                    line-height: 22px;
                }
            }
        }

        .new__ticket__message {
            font-weight: 500;
            font-size: 10px;
            display: flex;
            align-items: center;
            text-align: right;
            color: $light__theme_color;
            padding: 1px 10px;
            background: $primary__theme_color;
            border-radius: 6px;
            width: fit-content;

            #{$relaxed__mode__template__selector} {
                font-weight: 500;
                font-size: 10px;
                display: flex;
                align-items: center;
                text-align: right;
                color: $light__theme_color;
                padding: 1px 10px;
                background: $primary__theme_color;
                border-radius: 6px;
                width: fit-content;
            }

            @include media("<=medium-screen") {
                font-size: 14px;
                padding: 2px 10px;
                #{$relaxed__mode__template__selector} {
                    font-size: 14px;
                    padding: 2px 10px;
                }
            }
        }
    }
}
