@import "../mixins/mixins.scss";
@import "../_master.scss";
@import "./toggle.scss";
@import "./inputFile.scss";


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

textarea.form-control,
input.form-control,
select.form-select {
	// background: transparent !important; //incase of light and dark mode 
	color: #060213;
	border: 1px solid #dee1e5 !important;
	border-radius: 10px;
	font-size: 16px;
	letter-spacing: 0.25px;
	padding-top: 17px;
	padding-bottom: 17px;
	height: 55px;
	&.is-invalid:focus,
	&.is-valid:focus {
		outline: none;
		box-shadow: none;
	}
	&.is-invalid {
		border: 1px solid $danger__theme_color !important;
	}
	&--sm {
		height: 48px;
	}
	&--xsm {
		height: 40px;
	}
	&--xs {
		height: 33px;
	}
	&--borderless {
		border: 0 !important;
		border-left: 1px solid $primary__theme_color !important;
		border-radius: 0;
		font-size: 24px;
		height: 39px;
	}

	&:focus {
		border-color: $primary__theme_color !important;
		color: #000;
		outline: none;
		box-shadow: none;
	}
	&::placeholder {
		/* Chrome, Firefox, Opera, Safari 10.1+ */
		color: #696D8C;
		opacity: 1; /* Firefox */
	}
	&:disabled {
		cursor: not-allowed;
		background-color: #fff;
		// border: 1px solid #ebedf0 !important;
		// color: #b0b7c3;
	}
	@include responsiveMobile($sm) {
		height: 48px;
	}
}

.form-control {
	box-shadow: none !important;
	&[readonly]{
		// cursor: default;
		background: transparent !important;
	}
}

	
.input-color {
	.color-selector {
		width: 20px;
		height: 20px;
		content: "";
	}
}

textarea.form-control {
	// height: unset;
}

input.form-check-input {
	&.input-check-grey {
		background-color: #e6e6ed;
		border: 0;
		&:checked {
			background-color: $primary__theme_color;
		}
	}
	&:focus {
		outline: none;
		box-shadow: none;
	}
}

.input-group-text {
	&--left-radius {
		border-top-left-radius: 10px !important;
		border-bottom-left-radius: 10px !important;
	}
}

select.form-select {
	padding-top: 15px !important;
	&--right-radius {
		border-top-right-radius: 10px !important;
		border-bottom-right-radius: 10px !important;
	}
}

.extra-input-mssg {
	font-size: 14px;
}

.search-input {
	&--280 {
		width: 280px;
	}
	.input-group-text {
		border-radius: 10px;
		background-color: #fff;
		border: 1px solid #e0e0e0;
		&.reversed {
			border-left: none !important;
		}
		&.not-reversed {
			border-right: none !important;
		}
		&.focus {
			border-color: $primary__theme_color !important;
		}
	}
	input.form-control {
		border: 1px solid #e0e0e0 !important;
		border-radius: 10px;
		height: 45px;
		padding-top: 16px;
		padding-bottom: 16px;
		color: #060213;
		&.reversed {
			border-right: none !important;
		}
		&.not-reversed {
			border-left: none !important;
		}
		font-size: 14px;
		&:focus {
			border-color: $primary__theme_color !important;
			color: #000;
			outline: none;
			box-shadow: none;
		}
		&::placeholder {
			/* Chrome, Firefox, Opera, Safari 10.1+ */
			color: rgba(6, 2, 19, 0.3);
			font-size: 14px;
		}
	}
}

.input-err-message {
	font-size: 14px !important;
	color: $danger__theme_color !important;
	margin-bottom: 0;
	margin-top: 8px;
}

.react-quill {
	.ql-toolbar {
		border-radius: 10px;
		border: 1px solid rgba(30, 31, 32, 0.1);
		margin-bottom: 15px;
	}
	.ql-container {
		border-radius: 10px;
		border: 1px solid rgba(30, 31, 32, 0.1) !important;
		height: 244px;
	}
	&.is-invalid {
		.ql-container {
			border: 1px solid $danger__theme_color !important;
			.ql-editor {
				background-image: url("https://res.cloudinary.com/metacare/image/upload/v1642318605/icons/icon_yvfmbu.svg");
				background-position: right 15px top 12px;
				background-repeat: no-repeat;
				background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
			}
		}
	}
}

.input-multiple {
	&__bordered-box {
		border: 1px solid $border__theme_color;
		width: 100%;
		border-radius: 10px;
		.cancel {
			margin-left: 11px;
			cursor: pointer !important;
		}
	}
}


.form-check-label {
	white-space: nowrap !important;
	font-size: 16px !important;
    line-height: 12px;
}