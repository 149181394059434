@import "assets/scss/master";

.error__view {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    p {
        font-size: 0.875rem;
        text-align: center;
        color: $light__gray__theme_color;
        margin-bottom: 0;
        
        .retry__trigger {
            margin-left: 0.625rem;
            cursor: pointer;
            font-weight: bolder;
            &:hover {
                opacity: 0.8;
            }
        }
    }
}