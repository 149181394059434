@import "assets/scss/master";
@import "assets/scss/_include-media-lib/include-media";

.ticket__status {
    width: fit-content;
    white-space: nowrap;
    border-radius: 0.375rem;
    color: $text__default_color;
    font-size: 0.875rem;
    height: 1.625rem;
    display: flex;
    font-family: $primary_font_family--lighter;
    align-items: center;
    
    @include media("<=medium-screen") {
        height: 1rem;
        color: $light__gray__theme_color;
    }

    span {
        line-height: 0.625rem;
        letter-spacing: 0.009rem;

        #{$dark__mode__theme__selector} {
            color: $dark__mode__text__theme__color;
        }
    }
    
    .status__circle {
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        background: $green__theme_color;
        margin-right: 0.625rem;
        margin-bottom: -0.125rem;
        @include media("<=medium-screen") {
            margin-right: 0.313rem;
        }
    }
}