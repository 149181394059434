@import "assets/scss/master";
@import "assets/scss/_include-media-lib/include-media";

.dark__desktop {
    #{$relaxed__mode__template__selector} {
        background: $dark__mode__theme__background__color;
    }
}

.chat__container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    border: 0.063rem solid $border__theme_color;
    border-width: 0.063 0.063rem 0 0.063rem;
    overflow: hidden;

    margin-top: 1rem;

    @include media("<=small-screen") {
        position: relative;
        width: 100%;
        // align-items: center;
    }

    border-radius: 20px 20px 0px 0px;

    #{$relaxed__mode__template__selector} {
        border-width: 0 0.063rem 0 0.063rem;
        border: none !important;
        margin-top: 0rem;
        border-radius: none;
    }

    #{$dark__mode__theme__selector} {
        border: 0.063rem solid $dark__mode__theme__grey__color;
    }

    @include media("<=medium-screen") {
        border-radius: 0px;
        border: none !important;
    }

    .empty__chat--interface,
    .chat__interface {
        flex: 1 0 auto;
        position: relative;
    }

    .chat__input__container {
        bottom: 0;
        position: fixed;
        width: 100%;

        &.chat__input__high__index {
            #{$relaxed__mode__template__selector} {
                position: relative;
                z-index: 4;
            }
        }
    }
}

.empty__chat--interface {
    background: url(https://res.cloudinary.com/metacare/image/upload/v1654002124/Slice_1_ouxo0y.svg);
    height: 100%;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-align-items: center;
    width: 100%;
    #{$dark__mode__theme__selector} {
        background: none;
    }

    .empty__chat {
        @include media("<=small-screen") {
            margin-top: 0 !important;
        }
    }
}

.empty__group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 4rem;
    #{$relaxed__mode__template__selector} {
        width: 100%;
    }

    width: 100%;

    .preparing__tickets {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        margin: 24px 0 0;
        color: $light__theme_color;
    }
}
