@import "assets/scss/master";
@import "assets/scss/_include-media-lib/include-media";

.uploaded-files {
    overflow-y: scroll;
    // height: 5rem;
    padding: 10px 0px !important;
    #{$relaxed__mode__template__selector} {
        padding: 10px 0px !important;
    }
    width: 100%;
    .wrapper-files-container & {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
    }
    .uploaded-file {
        // margin-top: 1rem;
        border-radius: 100px;
        width: fit-content !important;
        height: 42px !important;

        @include media("<=medium-screen") {
            width: 100% !important;
        }

        span {
            font-weight: 400;
            font-size: 18px !important;
            line-height: 26px !important;
            margin-right: 15px;
        }
        
        #{$relaxed__mode__template__selector} {
            padding: 0 1rem !important;
            border-radius: 100px;
            width: fit-content;
            height: 42px;
            span {
                font-weight: 400;
                font-size: 18px !important;
                line-height: 26px !important;
                margin-right: 15px;
            }
        }

        svg {
            stroke: $primary__theme_color !important;
        }

        #{$dark__mode__theme__selector} {
            background: $dark__mode__theme__grey__color;

            svg {
                stroke: unset !important;
            }
        }

        #{$white__mode__theme__selector} {
            background: $light__theme_color !important;
            svg {
                stroke: $primary__theme_color !important;
            }
        }

        padding: 10px 16px !important;
        height: 58px;

        background: rgba(255, 255, 255, 0.1);
        border: 1px solid #0066f5;
        border-radius: 100px;

        width: 100%;

        display: flex;
        align-items: center;
        justify-content: space-between;

        div {
            display: flex;
            gap: 16px;
        }
        span {
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
            color: $dark__mode__text__theme__color;
            max-width: 200px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            #{$relaxed__mode__template__selector} {
                max-width: 200px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            #{$white__mode__theme__selector} {
                color: $dark__mode__theme__grey__color;
            }
        }
        &__icon {
            cursor: pointer;
        }
    }
}
