@import "assets/scss/master";
@import "assets/scss/_include-media-lib/include-media";

#ticketsHeader {
    position: sticky;
    top: 4.875rem;
    width: 100%;
    background-color: $light__theme_color;
    z-index: 1;

    display: none;
    #{$relaxed__mode__template__selector} {
        display: none;
    }
    
    #{$dark__mode__theme__selector} {
        background-color: $dark__mode__theme__background__color
    }

    @include media("<=medium-screen") {
        top: 3.5rem;
        position: fixed;
    }
    
    .agent-ticket--status {
        .ticket__status {
            display: none;
            @include media("<=medium-screen") {
                display: flex;
            }
        }
    }

    .tickets__action--list {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        gap: 0.625rem;
        margin-left: auto;

        .ticket__status__wrapper {
            display: block;

            @include media("<=medium-screen") {
                display: none !important;
            }

            #{$dark__mode__theme__selector} {
                border: 0.063rem solid $dark__mode__theme__grey__color;
            }
        }
    }
}

.ticket-header {
    padding: 1.563rem 2rem;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    border-bottom: 0.063rem solid $border__theme_color;
    border-top: 0.063rem solid $border__theme_color;
    
    #{$dark__mode__theme__selector} {
        border-bottom: 0.063rem solid $dark__mode__theme__grey__color;
        border-top: 0.063rem solid $dark__mode__theme__grey__color;
    }

    .ticket-header__icon {
        width: 2rem;
        height: 1.875rem;
        cursor: pointer;

        #{$relaxed__mode__template__selector} {
            svg {
                width: 20px;
                cursor: pointer;
            }
        }

        #{$dark__mode__theme__selector} {
            border: 0.063rem solid $dark__mode__theme__grey__color;

            svg {
                path {
                    stroke: $dark__mode__text__theme__color;
                }
            }
        }
    }

    @include media("<=medium-screen") {
        padding: 1rem 1.063rem;
        padding-bottom: 0.813rem;
    }
    .agents__name {
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 160%;
        color: $text__default_color;
        margin: 0;
        text-transform: capitalize;
        font-family: $primary_font_family--medium;

        #{$dark__mode__theme__selector} {
            color: $dark__mode__text__theme__color;
        }

        @include media("<=medium-screen") {
            font-size: 1.125rem;
            margin-bottom: -0.125rem;
        }
    }

    .subtitle-text {
        font-size: 0.875rem;
        line-height: 1.375rem;
        color: $muted__theme_color_2;
    }
}

.show-only-on-desktop {
    display: block;
    @include media("<=medium-screen") {
        display: none;
    }
}