@import "../mixins/mixins.scss";
@import "../_include-media-lib/include-media";
@import "../_master.scss";

.btn {
    font-family: $primary_font_family;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.15px;
    border-radius: 10px;
    padding: 7px 23px;
    height: 55px;
    white-space: nowrap;
    text-transform: capitalize !important;
    &-change-choice {
        background: rgba(0, 102, 245, 0.1);
        border-radius: 6px;
        height: 30px !important;
        border: 0;
        color: #6837EF;
        background: #6837EF21;
        font-size: 14px;
        padding-top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 0;
        line-height: 12px;
        &:hover {
            opacity: 0.8;
        }
        @include media("<=medium-screen") {
            padding: 0 10px;
            border-radius: 12px;
        }
    }
    &.new__convo {
        display: flex;
        height: 35px;
        background: $primary__theme_color;
        font-weight: 100;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        border-radius: 8px;
        letter-spacing: 0.15px;
        color: $light__theme_color;
        width: fit-content;
        margin-left: auto;

        #{$relaxed__mode__template__selector} {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
        }

        @include media("<=medium-screen") {
            #{$relaxed__mode__template__selector} {
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
            }
        }

        .icon {
            margin-right: 8px;
        }
        &.large {
            height: 56px;
            width: 90%;
            margin: auto;
        }
    }
    &--connect {
        border: 1px solid $border_highlight_color;
        height: auto;
        padding: 8px 16px;
        font-size: 14px;
    }
    &--add {
        color: $primary__theme_color !important;
        font-weight: lighter !important;
        font-size: 17px !important;
        letter-spacing: -0.2px !important;
        line-height: 15px !important;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        padding: 0;
        .plusIcon {
            margin-right: 0.5rem;
        }
    }
    &-primary {
        background: $primary__theme_color !important;
        border-color: $primary__theme_color !important;
    }
    &-sm {
        font-size: 14px;
        height: 48px;
    }
    &-xsm {
        height: 40px;
    }
    &-xs {
        font-size: 14px;
        height: 33px;
        display: flex;
        align-items: center;
    }
    &-muted {
        color: #060213;
    }
    &-faded {
        color: #b0b7c3;
    }
    &-success,
    &-danger {
        color: #fff;
    }
    &--downtime {
        background-color: #377dff;
        color: #fff;
    }
    &--file-label {
        border: 0;
        color: #060213;
        background-color: #f8f8f8;
        border-radius: 5px;
        font-size: 16px;
        font-family: $primary_font_family;
        font-weight: 400;
        height: 53px;
    }
    &--file-label-full {
        @extend .btn--file-label;
        height: 55px;
    }
    &[class*="btn-outline"]:hover {
        color: #fff;
    }
    &:focus {
        outline: none !important;
        box-shadow: none !important;
    }
    @include responsiveMobile($sm) {
        height: 48px;
    }
    &.transparent {
        border: none;
        outline: none;
        background: transparent;
        padding: 0 !important;

        &:hover {
            color: default;
        }
    }
    &.text__meta-purple {
        &:hover {
            color: #6837ef;
        }
    }
}
