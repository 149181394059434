:root {
    --default-primary-color: #6837ef;
}

// Fonts
$primary_font_family: "Gelion";
$primary_font_family--lighter: "Gelion Light";
$primary_font_family--medium: "Gelion-Medium";
$primary_font_family--semi-bold: "Gelion Semi";
$primary_font_family--bold: "Gelion Bold";

/* make the customizations */
$primary__theme_color: var(--default-primary-color);
$secondary__theme_color: #1d1d1d;
$danger__theme_color: #ff3364;
$info__theme_color: #557aff;
$dark__theme_color: #000000;
$black__theme_color: #060213;
$muted__theme_color: #b0b7c3;
$light__theme_color: #ffffff;
$success__theme_color: #1b9646;
$gray__theme_color: #2e2e2e;
$grey__theme_color: #171725;
$border__theme_color: #ecebf5;
$muted__theme_color_2: #696d8c;
$capsule_highlight_color: #0878e9;
$muted__border__theme_color: #ecebf566;
$pending__theme_color: #ff991f;
$pending__background__theme_color: #ff991f33;
$success__background__theme_color: #00875a33;
$danger__background__theme_color: #ff33641a;
$pimary__background__theme_color: #6837ef1a;
$border_highlight_color: #e1e1eb;
$text__default_color: #060213;
$option__fade_out: #a3a3c2;
$scroll__bar__track_color: #fafafc;
$scroll__bar__thumb_color: #e6e6ed;
$scroll__bar__thumb_hover_color: #4d4b4b;
$gray__out__theme_color: #f8f8fa;
$file__error__theme_color: #a3a3c3;
$inactive__button__theme_color: #f5f5f7;
$light__gray__theme_color: #696d8c;
$green__theme_color: #25bb87;
$ash__theme_color: #484848;
$yellow__theme__color: #fbbf27;
$dark__mode__theme__background__color: #1f272d;
$dark__mode__theme__grey__color: #2d333a;
$dark__mode__theme__dark__color: #191f24;
$dark__mode__text__theme__color: #d5d7d8;
$dark__mode__desktop__chat__theme__color: #47515c;
$dark__mode__connected__background: #252a2f;
$dark__mode__desktop__ticket__background: #24282e;
$option__change__theme__color: #838589;
$light__blue__theme__color: #0066f5;
$white__mode__theme__grey__color: #f6f5f8;
$white__mode__option__background: #edeeef;
$white__mode__input__background: #e9e7ee;
$white__mode__background: #f3f5f7;
$white__mode__options__background: #f7f8f9;
$white__mode__border__color: #e7ebef;
$relaxed__mode__template__selector: ".relaxed-mode &";
$dark__mode__theme__selector: ".dark-mode-default &";
$white__mode__theme__selector: ".white-mode-default &";
$ios__device__selector: ".ios__device__style &";
