@import "assets/scss/master";
@import "assets/scss/_include-media-lib/include-media";

.empty__chat {
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    width: 100%;

    @include media("<=medium-screen") {
        padding: 1rem;
    }

    &.with-margin {
        margin-top: 6rem;
    }

    svg {
        path {
            fill: $dark__mode__text__theme__color;
        }
    }
    
    p {
        font-family: $primary_font_family;
        font-size: 1rem;
        color: $muted__theme_color_2;
        margin-top: 1rem;
        margin-bottom: 0;
        white-space: pre-wrap;

        #{$dark__mode__theme__selector} {
            color: $dark__mode__text__theme__color;
            font-size: 18px;
            line-height: 26px;
            white-space: inherit;
        }
    }
}