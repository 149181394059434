@import "assets/scss/master";
@import "assets/scss/_include-media-lib/include-media";

.banner__message {
    // display: flex;
    display: none;
    gap: 15px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    padding: 10px 20px 10px 23px;
    border-radius: 100px;
    font-size: 14px;
    line-height: 20px;

    background: #f3f5f7;

    #{$dark__mode__theme__selector} {
        background: $dark__mode__theme__dark__color;
        color: #ffffff;
    }

    #{$relaxed__mode__template__selector} {
        #{$dark__mode__theme__selector} {
            background: #252a2f;
            color: #ffffff;
        }
    }

    #{$white__mode__theme__selector} {
        background: #f3f5f7;
        color: #060213;
    }

    #{$relaxed__mode__template__selector} {
        padding: 10px 20px 10px 23px;
        border-radius: 100px;
        font-size: 14px;
        line-height: 20px;
    }
    @include media("<=medium-screen") {
        width: 100%;
        gap: 0;

        #{$relaxed__mode__template__selector} {
            padding: 10px 27px 10px 28px;
            border-radius: 0;
        }

        #{$dark__mode__theme__selector} {
            background: #2d333a;
            color: #ffffff;
        }

        #{$white__mode__theme__selector} {
            background: #f6f5f8;
        }
    }

    & > div {
        display: flex;
        gap: 15px;
        align-items: center;
    }

    .close__banner__message {
        svg {
            cursor: pointer;
        }
    }

    svg {
        stroke: rgb(0, 105, 205);
    }

    &__text {
        text-align: left;

        .highlight {
            color: rgb(0, 105, 255);

            &.underline {
                text-decoration: underline;
            }
        }
    }

    svg {
        path {
            fill: $primary__theme_color;
        }
    }
}
