@import "assets/scss/master";

.action-response-time {
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 4px 7px;
    gap: 2px;
    width: fit-content;
    height: 22px;
    background: #E9F2FF;
    border-radius: 6px;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    color: #000000;
    margin-bottom: 8px;

    #{$relaxed__mode__template__selector} {
        border: none;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        gap: 10px;
    }
    
    #{$dark__mode__theme__selector} {
        color: $light__theme_color;
        background: $dark__mode__theme__background__color;
    }

    .response__time__clock {
        svg {
            #{$relaxed__mode__template__selector} {
                height: 22px;
                width: 22px;
            }
        
            path {
                #{$dark__mode__theme__selector} {
                    fill: $yellow__theme__color;
                }   
            }
        }
    }
}
