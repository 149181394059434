@import "assets/scss/master";
@import "assets/scss/_include-media-lib/include-media";

.big-container {
    @include media("<=medium-screen") {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.customer-verification {
    height: 85vh;
    @include media(">medium-screen") {
        #{$relaxed__mode__template__selector} {
            background-color: $dark__mode__theme__grey__color;
            border-radius: 20px;
            // height: 37.5rem;
            // margin-top: 24px;
        }

        #{$white__mode__theme__selector} {
            background-color: $light__theme_color;
            border: 1px solid $white__mode__border__color;
        }

        #{$dark__mode__theme__selector} {
            border-top: unset;
        }
    }

    @include media("<=medium-screen") {
        // #{$relaxed__mode__template__selector} {
        //     background: $dark__mode__theme__grey__color;
        //     border-radius: 20px 20px 0 0;
        //     border-radius: 20px;
        //     height: 37.5rem;
        //     margin: 0 20px 0 20px;

        // }

        #{$relaxed__mode__template__selector} {
            background: transparent;
            height: 37.5rem;
        }

        #{$white__mode__theme__selector} {
            background-color: $light__theme_color;
        }
    }

    .customer-save__action {
        @include media("<=medium-screen") {
            margin-top: 25vh;
        }

        &.customer-verify--margin {
            @include media("<=medium-screen") {
                margin-top: 30px;
            }

            @include media("<=xtra-small-screen") {
                margin-top: 0;
            }
        }
    }

    .customer-verify {
        &__icon {
            text-align: right;
            margin-top: 42px;
            margin-right: 139px;
            margin-bottom: 60px;

            @include media(">medium-screen") {
                display: flex;
                width: fit-content;
                margin: 0 20px 0 auto;

                #{$relaxed__mode__template__selector} {
                    padding-top: 20px;
                    display: flex;
                    width: fit-content;
                    margin: 0 20px 0 auto;
                }
            }

            .verify-icon {
                cursor: pointer;
            }

            @include media("<=medium-screen") {
                margin-top: 4rem;
                text-align: right;
                padding: 21px;
                margin-right: 0px;
                // margin-bottom: 0px;
                #{$relaxed__mode__template__selector} {
                    margin-top: 2rem;
                    margin-bottom: 0px;
                }
            }
        }

        &__loader__container {
            display: flex;
            justify-content: center;
        }

        &__form {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 350px;
            margin-left: auto;
            margin-right: auto;

            @include media(">medium-screen") {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 385px;
                margin-left: auto;
                margin-right: auto;
                height: 100%;
            }
            @include media("<=medium-screen") {
                width: 100%;
                padding: 20px;
            }
            .pincode-input-container {
                padding: 0.625rem 0;
                // margin: 1.25rem 0;
                white-space: nowrap;
                justify-content: space-between !important;
                display: flex;
                @include media("<=medium-screen") {
                    text-align: center;
                    padding: 0;
                    justify-content: space-between !important;
                    display: flex;
                    justify-content: center !important;
                    gap: 16px !important;
                }
                .pincode-input-text {
                    border-radius: 0.625rem;
                    font-weight: 600;
                    margin-left: 0;
                    // justify-content: space-evenly !important;
                    width: 80px !important;
                    height: 80px !important;
                    @include media("<=medium-screen") {
                        width: 60px !important;
                        height: 60px !important;
                        &:not(:last-child) {
                            margin-right: 0px !important;
                        }
                    }
                }
            }
        }

        &__banner__message__wrapper {
            margin-bottom: 12px;

            @include media("<=medium-screen") {
                margin-bottom: 40px;
            }
        }
    }
}
