@import "assets/scss/master";
@import "assets/scss/_include-media-lib/include-media";

.chat__header__user {
    @include media("<=medium-screen") {
        // #{$relaxed__mode__template__selector} {
        #{$dark__mode__theme__selector} {
            background-color: $dark__mode__theme__dark__color !important;
        }

        #{$white__mode__theme__selector} {
            background: $light__theme_color;
        }

        // }
    }

    // @include media(">medium-screen") {
    //     .logo {
    //         margin: auto;
    //         margin-left: 0rem !important;
    //     }
    // }
}

.chat__header__auth {
    margin-top: 12px !important;

    @include media(">medium-screen") {
        height: 72px !important;

        .logo {
            margin: auto !important;
        }
    }
    @include media("<=medium-screen") {
        height: 50px !important;
        margin-top: 0px !important;
    }

    #{$dark__mode__theme__selector} {
        background: $dark__mode__theme__grey__color;
        @include media(">medium-screen") {
            background: $dark__mode__theme__grey__color !important;
            border: 0.063px solid $dark__mode__theme__grey__color !important;
        }
    }

    #{$white__mode__theme__selector} {
        background: $light__theme_color !important;
        border: 1px solid $white__mode__border__color;
    }

    border-radius: 20px 20px 0px 0px;
    #{$relaxed__mode__template__selector} {
        border-radius: 100px !important;
    }
}

.header__auth {
    // margin-bottom: -10px;
    min-height: unset !important;

    #{$relaxed__mode__template__selector} {
        margin-bottom: 10px !important;
        min-height: 4.875rem !important;
    }
}

#header__wrapper {
    @include media("<=medium-screen") {
        // #{$relaxed__mode__template__selector} {
        display: block;
        position: fixed;
        width: 100%;
        height: 97px;
        height: fit-content;
        z-index: 3;

        // }
        margin-top: -18px;

        &.high__index {
            z-index: 6;
        }

        background: $light__theme_color;

        #{$dark__mode__theme__selector} {
            background: $dark__mode__theme__background__color;
        }

        #{$white__mode__theme__selector} {
            background: $light__theme_color;
        }
    }

    @media (min-width: 768px) and (max-width: 769px) {
        height: 97px;
        width: 82%;
    }

    #header {
        background-color: $light__theme_color;
        position: sticky;
        width: 100%;
        min-height: 4.875rem;
        top: 0px;
        z-index: 3;
        display: flex;
        align-items: center;

        // #{$relaxed__mode__template__selector} {
        margin-top: 0px;
        border-bottom: none !important;
        display: block;
        // }

        #{$relaxed__mode__template__selector} {
            margin-top: 12px;
        }

        #{$dark__mode__theme__selector} {
            background-color: $dark__mode__theme__background__color;
            border-bottom: 0.063rem solid $dark__mode__theme__grey__color;
        }

        #{$white__mode__theme__selector} {
            background: none;
        }

        @include media("<=medium-screen") {
            min-height: 50px;
            position: fixed;

            // #{$relaxed__mode__template__selector} {
            margin: 20px 3.2% 0;
            border-radius: 100px;
            border: none;
            width: 93.5%;
            z-index: 2;
            background: none;
            border-bottom: none;

            // margin-top: 20px !important;
            // }

            #{$white__mode__theme__selector} {
                border-bottom: none;
            }

            background: $light__theme_color;

            #{$dark__mode__theme__selector} {
                background: $dark__mode__theme__background__color;
            }

            #{$white__mode__theme__selector} {
                background: $light__theme_color;
            }

            padding-top: 20px;
            top: -20px;
        }

        @include media("<=small-screen") {
            // #{$relaxed__mode__template__selector} {
            margin: 0;
            width: 100%;
            padding: 0 20px 0;
            // }
            top: 0px;
            background-color: $light__theme_color;
            padding-bottom: 0px;
            padding-top: 20px;
        }

        @media (min-width: 768px) and (max-width: 769px) {
            width: 75.5%;
            border-radius: 0;
        }

        .chat__header {
            display: flex;
            align-items: center;
            height: 100%;
            padding: 0 2rem;
            justify-content: space-between;
            width: inherit;

            // #{$relaxed__mode__template__selector} {

            padding: 11px 2rem;
            width: 100%;
            border: 1px solid $white__mode__border__color;
            // }

            #{$relaxed__mode__template__selector} {
                border-radius: 100px;
                border-top: unset;
                background: $dark__mode__theme__dark__color !important;
            }

            #{$dark__mode__theme__selector} {
                background: $dark__mode__theme__grey__color;
                border: none;

                @include media(">medium-screen") {
                    background: transparent;
                    border-bottom: 0.063px solid $dark__mode__theme__grey__color;
                }
            }

            #{$white__mode__theme__selector} {
                background: $light__theme_color !important;
                border: 1px solid $white__mode__border__color;
            }
            @include media(">medium-screen") {
                margin-top: -1px;
            }

            .logo__left__aligned {
                margin: unset !important;
            }

            @include media("<=medium-screen") {
                // padding: 0 1rem;
                height: 3.5rem;
                border-radius: 100px;
                // #{$relaxed__mode__template__selector} {
                padding: 0 16px 0 23.5px;
                // }

                .logo {
                    margin: auto;
                }

                // .chat__header-save-convo {
                //     margin: unset !important;
                // }

                background: $light__theme_color;

                #{$dark__mode__theme__selector} {
                    background: $dark__mode__theme__grey__color !important;
                    border: none;
                }
            }

            @include media(">medium-screen") {
                // #{$relaxed__mode__template__selector} {
                // padding: 0 16px 0 23.5px;
                // height: 3.5rem;
                padding: 11px 28px;
                // }

                .logo {
                    margin: 0;
                }
            }

            #authTickets {
                display: contents;
                @include media("<=medium-screen") {
                    display: none;
                }
            }

            .chat__toggler {
                // display: none;

                span {
                    font-weight: 400;
                    font-size: 0.875rem;
                    color: $muted__theme_color_2;

                    #{$dark__mode__theme__selector} {
                        color: $dark__mode__text__theme__color;
                    }
                }

                @include media("<=medium-screen") {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    gap: 0.25rem;
                    height: 0.75rem;
                    span {
                        margin-top: 0.1rem;
                    }
                }

                @include media(">medium-screen") {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    gap: 0.25rem;
                    height: 0.75rem;
                    span {
                        margin-top: 0.063rem;
                    }
                }

                #{$dark__mode__theme__selector} {
                    svg {
                        path {
                            fill: $dark__mode__text__theme__color;
                        }
                    }
                }
            }

            .logo {
                // #{$relaxed__mode__template__selector} {
                display: flex;
                align-items: center;
                gap: 8px;
                // }

                .company__logo {
                    width: 36px;
                    height: 36px;
                    border-radius: 100%;
                    object-fit: contain;
                    cursor: pointer;
                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 100%;
                    }
                    @include media("<=medium-screen") {
                        max-width: 26px;
                        height: 26px;
                    }
                }

                .workspace__agent__name {
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 26px;
                    text-transform: capitalize;
                    cursor: pointer;
                    #{$dark__mode__theme__selector} {
                        color: $dark__mode__text__theme__color;
                    }

                    #{$white__mode__theme__selector} {
                        color: $dark__mode__theme__grey__color;
                    }

                    @include media("<=small-screen") {
                        font-weight: 550;
                        font-family: "Gelion-Medium" !important;
                    }

                    #{$ios__device__selector} {
                        letter-spacing: -0.05px !important;
                    }
                }
            }

            .ticket-header__icon {
                display: block;
                cursor: pointer;

                // #{$relaxed__mode__template__selector} {
                border: none;
                padding: 0;

                svg {
                    width: 20px;
                }
                // }
            }
        }
    }
}
.show-only-on-mobile {
    display: none;
    @include media("<=medium-screen") {
        display: block;
    }

    &.show-on-desktop {
        @include media(">medium-screen") {
            // #{$relaxed__mode__template__selector} {
            display: block;
            margin-left: 30px;
            // }
        }
    }

    .ticket-header__icon {
        cursor: pointer;

        // #{$relaxed__mode__template__selector} {
        border: none;
        padding: 0;

        svg {
            width: 20px;
        }
        // }

        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__text__theme__color;

            svg {
                path {
                    fill: $dark__mode__text__theme__color;
                }
            }
        }
    }
}
