@import "assets/scss/_include-media-lib/include-media";

.modal__preview {
    &--container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;

        .preview__media {
            object-fit: contain;
            height: auto;
            width: 50%;
            border-radius: 0.625rem;
            margin: 0 0.625rem;

            @include media("<=medium-screen") {
                width: 90%;
            }
        }

        .preview__file {
            height: 100%;
            width: 100%;
        }
    }
}