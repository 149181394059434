@import "assets/scss/master";
@import "assets/scss/_include-media-lib/include-media";

.live__chat--status {
    position: fixed;
    // top: 26%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 1;
    font-size: 0.875rem;

    // display: none;
    // #{$relaxed__mode__template__selector} {
    //    top:26%;
    // }
    top: 150px;

    @include media("<=medium-screen") {
        top: 120px;
        z-index: 0;
    }

    @include media("<=small-screen") {
        top: 110px;
        z-index: 0;
    }
}

.live__chat--save-button {
    padding: 4px 10px;
    gap: 8px;
    width: 151px;
    height: 26px;
    border-radius: 10px;
    border: none !important;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.2px;
    background-color: $primary__theme_color;
    color: #ffffff;
    margin-top: 0.5rem;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    @include media("<=medium-screen") {
        //   margin-top: -2rem;
    }
    &__icon {
        margin-top: -1px;
    }
}

.error__status {
    padding: 0.5rem 1rem;
    padding-right: 1.25rem;
    background-color: #fff5f5;
    border: 0.063rem solid #ffccc7 !important;
    border-radius: 0.5rem;
    border-radius: 0.5rem;
    color: #ff000096;
    cursor: pointer;

    display: flex;
    align-items: center;
    gap: 8px;
    
    #{$relaxed__mode__template__selector} {
        padding: 4px 10px;
        display: flex;
        align-items: center;
        gap: 8px;
        border-radius: 10px;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.2px;
    }

    #{$dark__mode__theme__selector} {
        border: 1px solid #f77150;
        color: #f77150;
        background: $dark__mode__theme__background__color;
    }
}

.reconnected {
    width: fit-content;
    padding: 0.25rem 0.625rem;
    border: 0.063rem solid $success__theme_color;
    font-size: 0.813rem;
    border-radius: 0.5rem;
    background: $scroll__bar__track_color;
    color: $success__theme_color;
    #{$dark__mode__theme__selector} {
        background: $dark__mode__connected__background;
        // color: $light__theme_color;
    }

    display: flex;
    align-items: center;
    cursor: pointer;
}

.connected {
    width: fit-content;
    padding: 0.25rem 0.625rem;
    border: 0.063rem solid $primary__theme_color;
    font-size: 0.813rem;
    border-radius: 0.5rem;
    background: $scroll__bar__track_color;

    #{$dark__mode__theme__selector} {
        background: $dark__mode__connected__background;
        color: $light__theme_color;
    }

    display: flex;
    align-items: center;

    #{$relaxed__mode__template__selector} {
        font-size: 13px;
        line-height: 24px;
        padding: 4px 10px;
        display: flex;
        align-items: center;
        color: $light__theme_color;
        background: $dark__mode__connected__background;
        border: 1px solid $primary__theme_color;
        border-radius: 8px;
    }

    #{$white__mode__theme__selector} {
        color: $text__default_color;
        background: $light__theme_color;
        border: 1px solid $white__mode__border__color;
    }
}
