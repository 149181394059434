@import "assets/scss//master.scss";

.home {
    font-family: $primary_font_family;
    height: 100%;

    .page {
        width: 100%;
        height: 100%;
    }
}
