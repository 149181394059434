@import "assets/scss/master";
@import "assets/scss/animate/animate.scss";
@import "assets/scss/_include-media-lib/include-media";

.full__page_loader {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $border__theme_color;
    position: absolute;
    width: 100%;
}

#metaInfinite {
    font-size: 8.5vw;
    font-weight: bolder;
    text-align: center;
    background-size: contain;
    width: fit-content;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    margin-top: 0;
    height: fit-content;
    margin-top: -20px;
    animation: shine-animation 3s infinite ease-in-out;
    -webkit-animation: shine-animation 3s infinite ease-in-out;
}
