@import "assets/scss/master";
@import "assets/scss/_include-media-lib/include-media";

.message-body {
    padding: 1.5rem;
    padding-top: 8rem;
    overflow: auto;
    height: 75vh;
    &::-webkit-scrollbar {
        scrollbar-width: 0.625rem;
    }

    @include media(">xtra-large-screen") {
        #{$relaxed__mode__template__selector} {
            height: 90vh !important;
        }
    }

    @include media(">large-screen") {
        #{$relaxed__mode__template__selector} {
            height: 78vh !important;
        }
    }

    // @include media(">medium-screen") {
    //     #{$relaxed__mode__template__selector} {
    //         height: 74vh;
    //     }
    // }

    @include media("<=medium-screen") {
        padding-left: 20px;
        padding-right: 20px;
        height: 100vh;
        padding-top: 40%;
        padding-bottom: 5%;

        @media all and (device-width: 390px) and (device-height: 844px) and (orientation: portrait) {
            height: 86vh;
        }

        #{$relaxed__mode__template__selector} {
            padding-top: 25%;
        }
    }

    @include media("<=small-screen") {
        padding-left: 1rem;
        padding-right: 1rem;
        height: 80vh;
        padding-top: 150px;
        // padding-bottom: 0%;
        // margin-top: 10vh;
        #{$relaxed__mode__template__selector} {
            // padding-top: 40%;
            // margin-top: 0;
            height: 100vh;
            @media all and (device-width: 390px) and (device-height: 844px) and (orientation: portrait) {
                height: 86vh;
            }
        }
    }

    &.message-body--margin {
        @include media("<=medium-screen") {
            #{$relaxed__mode__template__selector} {
                padding-top: 3%;
                height: 75vh;
            }
        }

        @include media("<=small-screen") {
            #{$relaxed__mode__template__selector} {
                padding-top: 5%;
            }
        }
    }
}
