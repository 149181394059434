@import "assets/scss/master";
@import "assets/scss/_include-media-lib/include-media";

.scheduled__branch__option {
    border-radius: 14px;
    background-color: $light__theme_color;
    cursor: pointer;
    outline: none;
    font-size: 14.5px;
    letter-spacing: 0.05px;
    padding: 7px 12px;
    transition: all 0.3s;

    #{$relaxed__mode__template__selector} {
        padding: 10px 37.25px;
        width: fit-content;
        font-size: 16px;
        line-height: 24px;
        border-radius: 100px;
    }

    @include media("<=medium-screen") {
        #{$relaxed__mode__template__selector} {
            width: 100%;
            font-size: 20px;
            line-height: 28px;
            padding: 14px 37.25px;
            text-align: center;
            border-radius: 100px;
        }

        #{$white__mode__theme__selector} {
            background: $white__mode__option__background;
        }
    }

    @include media("<=medium-small-screen") {
        #{$relaxed__mode__template__selector} {
            font-size: 18px;
            line-height: 24px;
        }
    }

    #{$dark__mode__theme__selector} {
        color: $dark__mode__text__theme__color;
        background: $dark__mode__theme__grey__color;
        border: 0.125rem solid $dark__mode__theme__grey__color;
    }

    #{$white__mode__theme__selector} {
        color: $muted__theme_color_2;
        background: $white__mode__option__background;
        border: none;
    }

    &.active {
        border: 1px solid $border_highlight_color;
        color: $text__default_color;

        #{$dark__mode__theme__selector} {
            color: $dark__mode__text__theme__color;
            border: 0.125rem solid $dark__mode__theme__grey__color;
        }

        #{$white__mode__theme__selector} {
            color: $muted__theme_color_2;
            border: none;
        }
    }

    &.inactive {
        border: 1px dashed $border_highlight_color;
        color: $option__fade_out;
        pointer-events: none;

        #{$dark__mode__theme__selector} {
            border: 0.125rem solid $dark__mode__theme__grey__color;
        }

        #{$white__mode__theme__selector} {
            border: none;
        }
    }

    &:hover {
        opacity: 0.5;
    }

    &:disabled {
        color: $text__default_color;
    }

    &.__fade_out {
        color: $option__fade_out !important;
        pointer-events: none;
    }

    &.selected {
        color: $text__default_color;
        border: 0.125rem solid $primary__theme_color !important;

        #{$relaxed__mode__template__selector} {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;
        }

        #{$dark__mode__theme__selector} {
            color: $dark__mode__text__theme__color;
            background: $dark__mode__theme__grey__color;
        }
    }

    .option__pause {
        display: flex;
        justify-content: center;
        margin-top: 5px;
        color: $primary__theme_color;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        gap: 5px;
    }
}