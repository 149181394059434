@import "assets/scss/master";
@import "assets/scss/_include-media-lib/include-media";

.otp__form {
    &__header {
        font-weight: 600;
        font-size: 2rem;
        line-height: 142%;
        color: $black__theme_color;

        // #{$relaxed__mode__template__selector} {
            text-align: center;
            font-size: 24px;
            line-height: 32px;
            margin: 0;

            @include media(">medium-screen") {
                font-size: 28px !important;
                text-align: center;
            }
        // }
        #{$dark__mode__theme__selector} {
            color: $white__mode__background;
        }
    }
    &__sub__text {
        font-size: 1.125rem;
        line-height: 1.625rem;
        color: $muted__theme_color_2;

        // #{$relaxed__mode__template__selector} {
            text-align: center;
            font-size: 1rem;
            line-height: 24px;
            margin-top: 14px;
            margin-bottom: 24px;

            @include media(">medium-screen") {
                font-size: 20px;
                line-height: 28px;
            }
        // }

        #{$dark__mode__theme__selector} {
            color: $dark__mode__text__theme__color;
        }
    }
    .my-3 {
        // #{$relaxed__mode__template__selector} {
            margin-top: 40px !important;
        // }
    }
    button {
        // #{$relaxed__mode__template__selector} {
            height: 60px !important;
        // }
    }
    .pincode-input-container {
        padding: 0.625rem 0;
        // margin: 1.25rem 0;
        white-space: nowrap;
        display: flex;
        justify-content: space-between !important;
        @include media("<=medium-screen") {
            text-align: center;
            padding: 0;

            // #{$relaxed__mode__template__selector} {
                justify-content: center !important;
                gap: 16px !important;
            // }
        }

        .pincode-input-text {
            border-radius: 0.625rem;
            font-weight: 600;
            margin-left: 0;
            border: 1px solid #dee1e5 !important;
            color: #11142d;
            background: #ffffff !important;

            // #{$relaxed__mode__template__selector} {
                border-radius: 14px;
                padding: 18px;
                width: 80px !important;
                height: 80px !important;
            // }

            #{$dark__mode__theme__selector} {
                border: 1px solid $dark__mode__theme__dark__color !important;
                color: #d5d7d8;
                background: $dark__mode__theme__dark__color !important;
            }

            #{$white__mode__theme__selector} {
                border: 1px solid $white__mode__background !important;
                color: $dark__theme_color;
                background-color: $white__mode__background !important;
            }

            &:focus {
                border: 1px solid $primary__theme_color !important;

                // #{$relaxed__mode__template__selector} {
                    background-color: $white__mode__background !important;
                    color: $dark__mode__theme__dark__color !important;
                // }
            }
            &:not(:last-child) {
                margin-right: 1rem !important;
            }
            @include media("<=medium-screen") {
                width: 40px !important;
                height: 40px !important;
                &:not(:last-child) {
                    margin-right: 0px !important;
                }

                // #{$relaxed__mode__template__selector} {
                    width: 60px !important;
                    height: 60px !important;
                // }
            }
        }
    }
}
