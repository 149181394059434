@import "assets/scss/master";

.dot__loader {
    animation: 1s bulge infinite ease-out;
    padding: 12px 16px;
    border-radius: 16px;

    &.dot__loader__background {
        background: $primary__theme_color;
        color: $light__theme_color !important;
        span {
            background-color: $light__theme_color !important;
        }
    }

    span {
        width: 10px;
        height: 10px;
        float: left;
        margin: 0 0.313rem;
        // background-color: $light__theme_color;
        background-color: $dark__mode__theme__background__color;
        display: block;
        border-radius: 50%;
        opacity: 0.4;
        @for $i from 1 through 3 {
            &:nth-of-type(#{$i}) {
                animation: 1s blink infinite ($i * 0.3333s);
            }
        }

        #{$white__mode__theme__selector} {
            background-color: $dark__mode__theme__background__color;
        }

        #{$dark__mode__theme__selector} {
            background-color: $light__theme_color;
        }
    }
}

@keyframes blink {
    50% {
        opacity: 1;
    }
}

@keyframes bulge {
    50% {
        transform: scale(1.05);
    }
}
