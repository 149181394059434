@import "assets/scss/master";
@import "assets/scss/_include-media-lib/include-media";

.d-content {
    display: contents;
}

#dummy {
    height: 6.25rem;
    display: block;
    width: 100%;

    &.dummy__margin {
        margin-top: 5rem;

        #{$relaxed__mode__template__selector} {
            margin-top: 6rem;
        }
    }

    @include media("<=medium-screen") {
        &.dummy__margin {
            #{$relaxed__mode__template__selector} {
                margin-top: 30vh;
            }
        }
    }
}