@import "assets/scss/master";

.popup__modal__content {
    width: fit-content !important;

    &.dark__mode__modal__content {
        background: $dark__mode__theme__dark__color;
        border: none;
    }

    &.relaxed__template__modal__content {
        width: 350px !important;
        border-radius: 20px !important;
        border: none;
    }
}
