ol.message-thread {
    padding: 0;
    width: 100%;
    margin: 0;
    // margin-bottom: 8rem;
    li {
        list-style: none;
    }
}

.message-thread__list {
    transform: none !important;
}
