@import "assets/scss/master";

.custom-iframe {
    height: 100%;
    width: 100%;
    &-loader {
        height: 100%;
        width: 100%;
        background-color: $light__theme_color;
        display: flex;
        justify-content: center;
        align-items: center;
        .iframe-spinner {
            color: official;
            display: inline-block;
            position: relative;
            width: 80px;
            height: 80px;
        }
        .iframe-spinner div {
            transform-origin: 40px 40px;
            animation: iframe-spinner 1.2s linear infinite;
        }
        .iframe-spinner div:after {
            content: " ";
            display: block;
            position: absolute;
            top: 3px;
            left: 37px;
            width: 6px;
            height: 18px;
            border-radius: 20%;
            background: $muted__theme_color;
        }
        .iframe-spinner div:nth-child(1) {
            transform: rotate(0deg);
            animation-delay: -1.1s;
        }
        .iframe-spinner div:nth-child(2) {
            transform: rotate(30deg);
            animation-delay: -1s;
        }
        .iframe-spinner div:nth-child(3) {
            transform: rotate(60deg);
            animation-delay: -0.9s;
        }
        .iframe-spinner div:nth-child(4) {
            transform: rotate(90deg);
            animation-delay: -0.8s;
        }
        .iframe-spinner div:nth-child(5) {
            transform: rotate(120deg);
            animation-delay: -0.7s;
        }
        .iframe-spinner div:nth-child(6) {
            transform: rotate(150deg);
            animation-delay: -0.6s;
        }
        .iframe-spinner div:nth-child(7) {
            transform: rotate(180deg);
            animation-delay: -0.5s;
        }
        .iframe-spinner div:nth-child(8) {
            transform: rotate(210deg);
            animation-delay: -0.4s;
        }
        .iframe-spinner div:nth-child(9) {
            transform: rotate(240deg);
            animation-delay: -0.3s;
        }
        .iframe-spinner div:nth-child(10) {
            transform: rotate(270deg);
            animation-delay: -0.2s;
        }
        .iframe-spinner div:nth-child(11) {
            transform: rotate(300deg);
            animation-delay: -0.1s;
        }
        .iframe-spinner div:nth-child(12) {
            transform: rotate(330deg);
            animation-delay: 0s;
        }
        @keyframes iframe-spinner {
            0% {
                opacity: 1;
            }
            100% {
                opacity: 0;
            }
        }
    }

    &-error {
        color: $danger__theme_color;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10%;
        div{
            text-align: center;
        }
    }
}
