@import "assets/scss/master";
@import "assets/scss/_include-media-lib/include-media";

.chat__input--container {
    padding: 0.375rem 1rem;
    border-top: 0.063rem solid $border__theme_color !important;
    border-bottom: 0.063rem solid $border__theme_color;
    overflow: hidden;
    border-bottom: none;
    padding: 20px 24px;
    
    border-right: none !important;
    border-left: none !important;

    @include media("<=medium-screen") {
        padding: 20px 10px;
    }

    #{$dark__mode__theme__selector} {
        background: $dark__mode__theme__background__color;
        border: none;
        border-top: 0.063rem solid $dark__mode__theme__grey__color !important;
        border-right: 0.063rem solid $dark__mode__theme__grey__color;
    }

    #{$relaxed__mode__template__selector} {
        padding: 0 1rem !important;
        border-radius: 100px;
        border: none;
        background: $dark__mode__theme__grey__color;
    }

    #{$white__mode__theme__selector} {
        background: $light__theme_color;
    }

    @include media("<=medium-screen") {
        margin-right: 0px;
        border-right: none;
    }

    .chat__input--group {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        height: fit-content;

        justify-content: space-between;
        align-items: center;
        padding: 10px 8px 10px 16px !important;
        height: 58px;

        border-radius: 100px;

        border: 1px solid #ecebf5;

        #{$dark__mode__theme__selector} {
            border: none;
            background: $dark__mode__theme__grey__color;
        }

        #{$white__mode__theme__selector} {
            border: 1px solid #ecebf5;
        }

        #{$relaxed__mode__template__selector} {
            border: none;
        }

        &__choose-option {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #a3a3ca;
            width: 100%;
            text-align: center;
            @include media("<=small-screen") {
                font-size: 18px !important;
            }
            #{$dark__mode__theme__selector} {
                color: $light__theme_color;
            }
        }

        @include media("<=medium-screen") {
            padding: 8px 8px 8px 8px !important;
            // height: 48px !important;
        }

        #{$relaxed__mode__template__selector} {
            border-radius: none;
            padding: unset !important;
        }

        &--inputs {
            display: flex;
            // align-items: flex-end;
            width: 100%;
            align-items: center;
            height: fit-content;
            height: 100%;
        }

        &--workmode__disabled {
            #{$white__mode__theme__selector} {
                background: $white__mode__background;
            }
        }

        .instruction__label__loader div:after {
            background-color: $primary__theme_color;
        }

        textarea,
        input {
            border: 0 !important;
            border-radius: 0 !important;
            resize: none;
            color: $dark__mode__theme__dark__color;

            #{$dark__mode__theme__selector} {
                color: $dark__mode__text__theme__color;
            }

            height: 100%;
            max-height: 138px !important;

            &:focus {
                color: $muted__theme_color_2;

                #{$dark__mode__theme__selector} {
                    color: $dark__mode__text__theme__color;
                }
            }

            &::-webkit-scrollbar {
                display: inline;
                width: 5px !important;
            }
            &::-webkit-scrollbar-track {
                background: #fafafc !important;
                border-radius: 3px !important;
                #{$dark__mode__theme__selector} {
                    background: $dark__mode__theme__grey__color !important;
                }
            }
            &::-webkit-scrollbar-thumb {
                background: #e6e6ed;
                border-radius: 3px;
                #{$dark__mode__theme__selector} {
                    background: $dark__mode__theme__background__color !important;
                }
            }
            &::-webkit-scrollbar-thumb:hover {
                background: #4d4b4b;
            }
        }
        .select__box {
            margin-top: -2rem;
            margin-left: 1rem;
            @include media("<=medium-screen") {
                margin-top: -1rem;
                margin-left: 0.5rem;
            }
        }
        .send__button {
            font-size: 0.875rem;
            color: $option__fade_out;
            background: $inactive__button__theme_color;
            border: 0;
            border-radius: 0.5rem;
            // padding: 1.063rem 1.5rem !important;
            height: 3.625rem;
            display: flex;
            justify-content: center;
            align-items: center;
            // padding: 0;
            // margin-left: auto;

            padding: 0px !important;
            width: 38px;
            height: 38px;
            border-radius: 50%;
            span {
                display: none;
            }

            @include media("<=medium-screen") {
                height: 1.9rem;
            }

            #{$relaxed__mode__template__selector} {
                padding: 0px !important;
                height: 2rem;
                width: 2rem;
                border-radius: 50%;
                span {
                    display: none;
                }

                @include media("<=medium-screen") {
                    height: 1.9rem;
                }
            }

            @include media("<=medium-screen") {
                padding: 0px !important;
                width: 38px;
                height: 38px;
                border-radius: 50%;
                span {
                    display: none;
                }

                #{$relaxed__mode__template__selector} {
                    // margin-bottom: 0.61rem;
                    margin-bottom: 0rem;
                    height: 2rem;
                    width: 2rem;
                }
            }

            @include media("<=small-screen") {
                #{$relaxed__mode__template__selector} {
                    // margin-bottom: 0.5rem;
                    margin-bottom: 0rem;
                }
            }

            svg {
                width: 60%;
                margin: 0;

                #{$relaxed__mode__template__selector} {
                    width: 60%;
                    margin: 0;
                }

                @include media("<=medium-screen") {
                    width: 60%;
                    margin: 0;
                }
                path {
                    fill: #92929d;
                }
            }
            &.active {
                background: $primary__theme_color;
                color: $light__theme_color;
                svg path {
                    fill: $light__theme_color;
                }

                .send__button__loader div:after {
                    background-color: $light__theme_color;
                }
            }
        }
    }
}

.file__error {
    font-size: 0.875rem;
    line-height: 1rem;
    color: $file__error__theme_color;
    text-align: center;
    margin-top: 0 !important;
    cursor: pointer;
}

.chatInputFormSubmit {
    @include media("<=medium-screen") {
        padding: 16px;
        .chat__input--container {
            padding-left: 0;
            padding-right: 0;
            border: none;
        }

        #{$dark__mode__theme__selector} {
            background: transparent !important;
        }

        #{$white__mode__theme__selector} {
            background: $white__mode__background !important;
        }
    }
}

#chatInput {
    position: sticky;
    bottom: 0;
    background-color: $light__theme_color;
    margin-right: 0.375rem;

    #{$dark__mode__theme__selector} {
        background: transparent;
    }

    #{$white__mode__theme__selector} {
        background: $white__mode__background;
    }

    .form-control {
        background: transparent;
        &::placeholder {
            color: $option__fade_out;
        }
    }

    @include media("<=medium-screen") {
        margin-right: 0 !important;
    }
}

.chatInput-form-select {
    padding: 0px 45px 0px 45px !important;
}

.disallowed__section {
    #chatInput {
        background: $gray__out__theme_color;
        margin-right: 0.375rem;

        #{$dark__mode__theme__selector} {
            background: $dark__mode__theme__dark__color;
        }

        #{$white__mode__theme__selector} {
            background: $white__mode__background;
        }
    }
}

.chat__input__relaxed__button {
    padding: 14px 8px !important;
    width: 100% !important;
    border-radius: 100px !important;
    margin-top: 1rem !important;
    margin-bottom: 26px;
    height: 52px !important;
    gap: 10px !important;
    font-weight: 500 !important;
    font-size: 20px !important;
    line-height: 24px !important;
}

.chat__input__date__button {
    border-radius: 10px !important;
}

.chat__input__relaxed__hide-button {
    display: none !important;
}

#inputGroup {
    position: fixed;
    bottom: 0;
    -webkit-transform: translateZ(0);

    #{$dark__mode__theme__selector} {
        background: $dark__mode__theme__background__color;
    }

    #{$relaxed__mode__template__selector} {
        padding: 20px 10px 0;
        background: $dark__mode__theme__dark__color;
    }

    #{$white__mode__theme__selector} {
        background: $white__mode__background;
    }

    @include media(">medium-screen") {
        #{$relaxed__mode__template__selector} {
            background: $dark__mode__theme__dark__color !important;
            border-radius: 0px 0px 20px 20px;
        }

        #{$white__mode__theme__selector} {
            background: $white__mode__background !important;
        }
    }
}

.chat__input__error {
    color: $light__gray__theme_color;
    z-index: 100;
    font-size: 14px;
    margin-top: -5px;
    text-align: center;
    display: block;
    margin-bottom: -5px;
    background-color: transparent !important;
    b {
        display: none;
    }
    #{$dark__mode__theme__selector} {
        color: $muted__theme_color_2;
        background-color: $dark__mode__theme__background__color !important;
    }
}
